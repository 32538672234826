import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { DepartmentService } from '../../common/services/department.service';
import { GroupService } from '../../common/services/group.service';
import { UserService } from '../../common/services/user.service';
import { MatSnackBar, MatSort, MatSortable, MatTableDataSource, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-departments',
    templateUrl: './departments.component.html',
    styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {

	public filterForm: FormGroup;

	public departments;
	public groups;
	public users;

	public displayedColumns = [ 'name', 'group_id', 'label', 'coordinator_id', 'active', 'actions'];
	@HostBinding('class') classes = 'full-height no-head';
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		private sb: MatSnackBar,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private ds: DepartmentService,
		private UserService: UserService,
		private gs: GroupService
	) { }

	ngOnInit() {

		this.filterForm = this.formBuilder.group({
            'name': new FormControl()    
        });

		// this.ds.all().subscribe(r => {
		// 	this.departments = new MatTableDataSource(<any> r);
		// 	this.departments.sort = this.sort;

		// });
		this.gs.all().subscribe(r => {
			this.groups = r;

		});

		this.UserService.getCoordinators().subscribe(r => {
	      this.users = r;
	    });

		this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({
                'name': v['name']
            }, {emitEvent: false});
            this.ds.filter(v).subscribe(r => {
                this.departments = new MatTableDataSource(<any> r);
				this.departments.sort = this.sort; 
				this.departments.paginator = this.paginator;
            });
        });

        this.filterForm.valueChanges.pipe(
            debounceTime(500),//waits .5 seconds after input changes so we don't make too many requests
        ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });
	}

	getGroupNameById(id){
		let name = '';
		this.groups.forEach((v) => {
			if(v.id == id)
				name = v.name;
		});
		return name;
	}

	getCoordinatorNameById(id){
		let name = '';
		this.users.forEach((v) => {
			if(v.id == id)
				name = v.first_name + ' ' + v.last_name;
		});
		return name;
	}

	reset(){
        this.router.navigate([], {queryParams: {}});
    }

	delete(id){
		if(confirm("Are you sure that you want to delete department #" + id + "?")){
			this.ds.delete(id).subscribe(r => {
				this.sb.open("Deleted!", null, {duration: 3000});
				this.ngOnInit();
			});
		}
	}

	print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }
}
