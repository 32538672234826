import { Model } from './model';
import { State } from './state';
import { Country } from './country';


export class Address extends Model{
    address_1: string = null;
    address_2: string = null;
    address_3: string = null;
    city: string = null;
    state_id: number = null;
    country_id: number = null;
    postal_code: number = null;
    state: State;
    country: Country;
    other_state: string = null;
    other_country: string = null;
}
