import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { PaymentsService } from '../../common/services/payments.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../common/services/department.service';
import { GroupService } from '../../common/services/group.service';
import { CompanyTypeService } from '../../common/services/company-type.service';
import { ContractStatusService } from '../../common/services/contract-status.service';
import { Company } from '../../models/company';
import { MatSnackBar, MatSort, MatSortable, MatTableDataSource, MatPaginator } from '@angular/material';
import { log } from 'src/app/common/log/log';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/common/services/auth.service';
import { debounceTime } from 'rxjs/operators'

@Component({
    selector: 'app-companies',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.css']
})


export class PaymentsComponent implements OnInit {
    public filterForm: FormGroup;

    public payments;

    @ViewChild('TABLE') table: ElementRef;

    public displayedColumns = [
        'company',
        'credit_card_type',
        'amount',
        'invoice',
        'transaction_number',
        'paid',
        'actions'
    ];
    
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        public formBuilder: FormBuilder,
        public PaymentsService: PaymentsService,
        public route: ActivatedRoute,
        public router: Router,
        public _snack: MatSnackBar
    ) { }

    ngOnInit() {

       
        this.filterForm = this.formBuilder.group({
            company: new FormControl('')
        });

        this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({
                'company': v['company'] || null,
            }, {emitEvent: false});
            this.PaymentsService.filterPayments(v).subscribe(r => {
              this.payments = new MatTableDataSource(<any> r);
              this.payments.sort = this.sort;
              this.payments.paginator = this.paginator;
            })
        });

        this.filterForm.valueChanges.pipe(
            debounceTime(500),
          ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });
    }


    reset() {
        this.router.navigate([], {queryParams: {}});
    }

    ExportTOExcel() {

      this.payments.paginator.pageSize = this.payments.filteredData.length;
      this.payments.paginator._emitPageEvent(0);
      // set 'dontUseColumn' to the title of unwanted column
      var dontUseColumn = "Actions";
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.payments._renderData._value);

      this.payments.paginator.pageSize = 25;
      this.payments.paginator._emitPageEvent(0);

      var formattedWS = {};
      var badColumn;

      Object.keys(ws).forEach(function (item) {
          if(item.includes("1") && ws[item]['v'] == dontUseColumn){
              badColumn = item.charAt(0);
          }
          if(!item.includes(badColumn)){
              if(ws[item]['v'] == " block "){
                  ws[item]['v'] = "no";
              }
              if(ws[item]['v'] == " done "){
                  ws[item]['v'] = "yes";
              }
              formattedWS[item] = ws[item];
          }
      });
      
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'payments_spreadsheet_'+date+'.xlsx');
      
    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
                  th {
                      font-weight: bold;
                      font-size: 12px;
                      color: #888;
                  }
                  th:last-of-type {
                      display: none;
                  }
                  td {
                      font-size: 12px;
                      color: #222;
                      padding: 5px;
                      text-align: center;
                      border-bottom: 1px solid #ccc;
                  }
                  td:last-of-type {
                      display: none;
                  }
              </style>
            </head>
            <body onload="window.print();window.close()">
            ${finalPrintContents}
            </body>
          </html>`
        );
        popupWin.document.close();
    }

}