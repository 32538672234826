import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { AuthService, AuthGroups } from './common/services/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SidebarService } from './common/services/sidebar.service';
import { MenuService } from './common/services/menu.service';
import { UserService } from './common/services/user.service';
import { CompanyService } from './common/services/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { log } from 'src/app/common/log/log';
import { User } from './models/user';
import { AnalyticsService } from './common/services/analytics.service';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'product-walk-client';
	mobileQuery: MediaQueryList;
	menus=[];
	allMenus;
	user;
	contractStatus;
	shipOnly: boolean;
	currentRoute = "Product Walk 2020";
    snackDismissed: boolean = true;
	private _destroyed$ = new Subject<void>();
	private _mobileQueryListener: () => void;
    authGroups = AuthGroups

	constructor(
		public us: UserService,
		public AuthService: AuthService,
		public SidebarService: SidebarService,
		public router: Router,
		public route: ActivatedRoute,
		public changeDetectorRef: ChangeDetectorRef,
		public media: MediaMatcher,
        public MenuService: MenuService,
        public cs: CompanyService,
        private _snackBar: MatSnackBar,
		public AnalyticsService: AnalyticsService
	){

		let user = this.AuthService.getUser()

		this.mobileQuery = media.matchMedia('(max-width: 960px)');
    	this._mobileQueryListener = () => {
			this.setMobile();
    		changeDetectorRef.detectChanges();
    	}
    	this.setMobile();
    	this.mobileQuery.addListener(this._mobileQueryListener);

    	this.router.events.subscribe(val => {
    		this.contractStatus = this.AuthService.getContractStatus()
    		this.shipOnly = JSON.parse(this.AuthService.getShipOnly())



    		this.setMobile();
            log.Debug("router val: ", val)

            //TODO update this so only suppliers see snack
            if( this.AuthService.getRole() != 'vendor' && this.snackDismissed && val instanceof NavigationEnd && user.is_primary && !this.shipOnly && (this.contractStatus == "Presented" || this.contractStatus == "Not Presented" ) ) {
                this.snackDismissed = false

                let snack = this._snackBar.open("Please review and accept your contract.", "Go", {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    panelClass: ['yellow-snackbar']
                })
                snack.onAction().subscribe( a => {
                    this.router.navigate(['/app/contract']);
                })
                snack.afterDismissed().subscribe( val => {
                    this.snackDismissed = true
                })
            }
    	});

    	this.user = new User(JSON.parse(localStorage.getItem("user")));
        this.us.getByID(this.user['id']).subscribe((u: User) => {
            this.user = u;
        });
        if(this.user.role_id !== 4){
            this.MenuService.getAllSorted().subscribe(r => {
				this.allMenus = r;
				if(this.shipOnly){
					this.allMenus.forEach(menu => {
						if(menu["name"] != 'Invoice' && menu["name"] != 'Registration'){
							this.menus.push(menu)
						}
					});
				}else{ this.menus = this.allMenus }
				this.menus.forEach(menu => {
					if(this.router.isActive(menu["href"], false)){
						this.currentRoute = menu["name"];
					}
				});
	    	});
        }else{
            this.MenuService.getUserMenus().subscribe(r => {
				this.allMenus = r;
				if(this.shipOnly){
					this.allMenus.forEach(menu => {
						if(menu["name"] != 'Invoice' && menu["name"] != 'Registration'){
							this.menus.push(menu)
						}
					});
				}else{ this.menus = this.allMenus }
				this.menus.forEach(menu => {
					if(this.router.isActive(menu["href"], false)){
						this.currentRoute = menu["name"];
					}
				});
	    	});
        }

		this.router.events.pipe(
			takeUntil(this._destroyed$),
			filter(r => r instanceof NavigationEnd),
			switchMap(_ => this.AnalyticsService.create(
					{item: this.router.url, user_id: this.user.id, company_id: this.user.company_id}
				))
		).subscribe( r => console.log("analytic: ", r))

	}

	navigateTo(route){
		this.router.navigateByUrl("/app/" + route);
	}

	setMobile(){
		this.SidebarService.isMobile = this.mobileQuery.matches;
		this.SidebarService.close();
	}

	toggleMenu(){
		this.SidebarService.toggle();
	}

	ngOnDestroy(): void {
	    this.mobileQuery.removeListener(this._mobileQueryListener);
		this._destroyed$.next();
        this._destroyed$.complete();
	}

	hasMenu(){
		return this.SidebarService.pageHasSidebar();
	}

	logout(){
		this.AuthService.logout();
		this.router.navigate(['/login']);
	}
}
