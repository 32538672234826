import { Injectable } from '@angular/core';
import { switchMap, map, filter } from 'rxjs/operators';
import { log } from 'src/app/common/log/log';

@Injectable({
    providedIn: 'root'
})

export class AppService {

    constructor(){}

    isActiveFilter() {
        return map((items: any[]) => {
            log.Debug("items: ", items)
            return items.filter( item => item.active )
        })
    }

}