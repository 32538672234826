import { Component, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material';

import { ShippingDestinationService } from '../../../common/services/shipping-destination.service';
import { ShippingDestination } from "../../../models/shipping-destination";

import { StateService } from '../../../common/services/state.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-shipping-destination',
  templateUrl: './add-shipping-destination.component.html',
  styleUrls: ['./add-shipping-destination.component.css']
})
export class AddShippingDestinationComponent implements OnInit {
  @HostBinding('class') classes = 'full-height no-head';

  public form: FormGroup;
  public addressForm: FormGroup;
  public states;

  constructor(
    public ShippingDestinationService: ShippingDestinationService,
    public StateService: StateService,
    public FormBuilder: FormBuilder,
    public MatSnackBar: MatSnackBar,
    public Router: Router,
    private _location: Location
  ) { }

  ngOnInit() {
    this.form = this.FormBuilder.group(new ShippingDestination());

    this.StateService.getAll().subscribe(response => {
      this.states = response;
    });
  }

  save(e) {
    this.ShippingDestinationService.save(this.form.value).subscribe(response => {
      this.MatSnackBar.open("Shipping Destination Added!", null, { duration: 3000 });
      this.Router.navigate(['/', 'app', 'admin', 'shipping_destinations']);
    });
  }

}
