import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MenuService } from '../../../common/services/menu.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
	selector: 'app-add-menu',
	templateUrl: './add-menu.component.html',
	styleUrls: ['./add-menu.component.css']
})
export class AddMenuComponent implements OnInit {

	public menu: FormGroup;

	constructor(
		private FormBuilder: FormBuilder,
		public Location: Location,
		private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router
	) { }

	ngOnInit() {
		this.menu = this.FormBuilder.group(new Menu());
	}

	save(){
		this.MenuService.save(this.menu.value).subscribe(r => {
			this.MatSnackBar.open("Menu Saved!", null, {duration:3000});
			this.Router.navigate(["/", 'app', 'admin', 'menus']);
		});
	}

}
