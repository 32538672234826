import { Component, OnInit, HostBinding } from '@angular/core';
import { ShippingDestinationService } from '../../common/services/shipping-destination.service';
import { StateService } from '../../common/services/state.service';
import { LockDatesDialogComponent } from './lock-dates-dialog/lock-dates-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-shipping-destinations',
    templateUrl: './shipping-destinations.component.html',
    styleUrls: ['./shipping-destinations.component.css']
})
export class ShippingDestinationsComponent implements OnInit {

    public shippingDestinations;
    public states; 

    public displayedColumns = [
        'name', 'ship_company', 'address_1', 'address_2', 'address_3',
        'city', 'state', 'postal_code', 'inbound_lock',
        'outbound_lock', 'active', 'actions'];

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        private ShippingDestinationService: ShippingDestinationService,
        private StateService: StateService,
        private MatDialog: MatDialog
    ) { }

    ngOnInit() {
        this.ShippingDestinationService.all().subscribe(r => {
            this.shippingDestinations = r;
        });

        this.StateService.getAll().subscribe(r => {
            this.states = r;
        });
    }

    getStateNameById(id){
        let name = "";
        if(this.states){
            this.states.forEach(v => {
                if(v.id == id){
                    name = v.long_name;
                }
            });
        }
        return name;
    }

    editLockDates(sd){
        this.MatDialog.open(LockDatesDialogComponent, {
            data:sd
        });
    }

    delete(sd){
        if(confirm("Are you sure that you want to delete "+ sd.name + "?")){
            this.ShippingDestinationService.delete(sd.id).subscribe(r => {
                this.ngOnInit();
            });
        }
    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

}
