import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { Company } from '../../models/company';
import { log } from 'src/app/common/log/log';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class RegistrationService {

	constructor(
		public http: HttpClient,
        private auth: AuthService
    ) { }


    delete(id){
        return this.http.delete(environment.api + "/registrations/delete/" + id);
    }

    create(data) {
        return this.http.post(environment.api + "/registrations/create", data);
    }

    update(data){
        return this.http.put(environment.api + "/registrations/update/" + data.id, data);
    }

    save(data){
        return data.id ? this.update(data):  this.create(data);
    }

    all(){
        return this.http.get(environment.api + "/registrations/all");
    }

    get(id){
        return this.http.get(environment.api + "/registrations/by_id/" + id);
    }

    filter(params){
        return this.http.get(environment.api + "/registrations/filter", {params: params});
    }

    filterForExport(params){
        return this.http.get(environment.api + "/registrations/filter_for_export", {params: params});
    }
}
