import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

	@HostBinding('class') classes = 'full-height no-head';

  constructor() { }

  ngOnInit() {
  }

}
