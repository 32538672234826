import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material';
import { DepartmentService } from '../../common/services/department.service';
import { CompanyService } from '../../common/services/company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ManifestService } from 'src/app/common/services/manifest.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  public departments;
  public companyInvoice;
  public manifestLineItems;
  public noManifestCompanies;
  public noAuthorizedShippingDestinationsCompanies;
  public shippingDestinationsCompanies;

  constructor(
    public ds: DepartmentService,
    public cs: CompanyService,
    private snackBar: MatSnackBar,
    public router: Router,
    public route: ActivatedRoute,
    private ManifestService: ManifestService,
  ) { }

  ngOnInit() {

    this.ds.statuses().subscribe(r => {
      this.departments = r;
    });


  }

  ExportContractStatus() {

    this.ds.statuses().subscribe(r => {
      this.departments = r;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.departments);
      var formattedWS = {};

      Object.keys(ws).forEach(function(item) {
              formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'departments_report_'+date+'.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!',null ,{duration: 3000});
    });

  }

  ExportInvoiceSummary() {

    this.cs.invoiceReport().subscribe(r => {
      this.companyInvoice = r;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.companyInvoice);
      var formattedWS = {};

      Object.keys(ws).forEach(function(item) {
              formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'invoice_summary_report_'+date+'.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!',null ,{duration: 3000});
    });

  }

  ExportDonations() {
    this.ManifestService.donationsReport().subscribe(r => {
      this.manifestLineItems = r
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.manifestLineItems);
      var formattedWS = {};

      Object.keys(ws).forEach(function(item) {
              formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'donations_report_'+date+'.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!',null ,{duration: 3000});
    });
  }

  ExportNoManifests() {
    this.cs.noManifestReport().subscribe(r => {
      this.noManifestCompanies = r
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.noManifestCompanies);
      var formattedWS = {};

      Object.keys(ws).forEach(function(item) {
              formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'no_manifests_report_'+date+'.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!',null ,{duration: 3000});
    });
  }

  ExportNoAuthorizedShippingDestinations() {
    this.cs.noAuthorizedShippingDestinationsReport().subscribe(r => {
      this.noAuthorizedShippingDestinationsCompanies = r
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.noAuthorizedShippingDestinationsCompanies);
      var formattedWS = {};

      Object.keys(ws).forEach(function(item) {
              formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'no_authorized_shipping_destinations_report_'+date+'.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!',null ,{duration: 3000});
    })
  }

  ExportSupplierShippingDestinations() {
    this.cs.shippingDestinationsReport().subscribe(r => {
      this.shippingDestinationsCompanies = r
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.shippingDestinationsCompanies);
      var formattedWS = {};

      Object.keys(ws).forEach(function(item) {
              formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'shipping_destinations_report_'+date+'.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!',null ,{duration: 3000});
    })
  }

}
