import { Model } from './model';

export class Manifest extends Model {
    id: number = null;
    company_id: number = null;
    shipping_destination_id: number = null;
    shipping_contact_name: string = null;
    shipping_contact_phone: string = null;
    shipping_contact_email: string = null;
    manifest_number: number = null;
    brand: string = null;
    shipment_method: string = null;
    tls: string = null;
    origination_address: string = null;
    ship_date: Date = null;
    arrive_date: Date = null;
    is_customs_shipment: Boolean = null;
}