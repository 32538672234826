import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { Company } from '../../models/company';
import { log } from 'src/app/common/log/log';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(
        public http: HttpClient,
        private auth: AuthService
    ) { }

    getCompanies(params?) {
        params = params || {}
        return this.http.get(environment.api + '/admin/companies/all', { params: params });
    }

    getCompaniesForExport(params?) {
        params = params || {}
        return this.http.get(environment.api + '/admin/companies/all_export', { params: params });
    }

    all() {
        return this.http.get(environment.api + '/companies/all');
    }

    allSorted() {
        return this.http.get(environment.api + '/companies/all_sorted');
    }

    getCompanyByInvitationCode(code) {
        let response = this.http.get(environment.api + '/companies/by_invite_code/' + code).pipe(
            map(r => {
                let company = r['Company']
                if (Boolean(r['ContractStatus'])) {
                    company['contract_status'] = r['ContractStatus']
                }
                return company
            })
        );
        log.Debug('response: ', response)
        return response
    }

    get(id) {
        return this.http.get(environment.api + '/companies/by_id/' + id);
    }

    invoices(params) {
        return this.http.get(environment.api + '/invoices/filter', { params: params });
    }

    invoiceXLS(id) {
        return this.http.get(environment.api + '/invoice/download/' + id, { responseType: 'blob' });
    }

    invoicePDF(id) {
        return this.http.get(environment.api + '/invoice/pdf/' + id, { responseType: 'blob' });
    }

    myInvoicePDF() {
        return this.http.get(environment.api + '/invoice/mypdf', { responseType: 'blob' });
    }

    bulkInvoiceZip(ids) {
        return this.http.post(environment.api + '/invoices/bulk_download_for_companies', ids, { responseType: 'blob' })
    }

    admin_create(data) {
        return this.http.post(environment.api + '/admin/companies/create', data);
    }

    admin_update(data) {
        return this.http.put(environment.api + '/admin/companies/update/' + data.id, data);
    }

    updateContactInfoByID(data) {
        return this.http.put(environment.api + '/admin/companies/update_contact_info_by_id/' + data.id, data);
    }

    admin_save(data) {
        return data.id ? this.admin_update(data) : this.admin_create(data);
    }

    createPrimarySetup(data) {
        return this.http.post(environment.api + '/companies/create_primary_setup', data);
    }

    updatePrimarySetup(data) {
        return this.http.put(environment.api + '/companies/update_primary_setup/' + data.id, data);
    }

    savePrimarySetup(data) {
        return data.id ? this.updatePrimarySetup(data) : this.createPrimarySetup(data);
    }

    create(data) {
        return this.http.post(environment.api + '/companies/create', data);
    }

    update(data) {
        return this.http.put(environment.api + '/companies/update/' + data.id, data);
    }

    save(data) {
        return data.id ? this.update(data) : this.create(data);
    }

    delete(id) {
        return this.http.delete(environment.api + '/admin/companies/delete/' + id);
    }

    validateImport(data) {
        return this.http.post(environment.api + '/admin/companies/validate_import', data);
    }

    invite(data) {
        return this.http.post(environment.api + '/admin/companies/import', data);
    }

    updateAttendees(data) {
        return this.http.post(environment.api + '/admin/companies/updateAttendees', data);
    }

    getChosenDestinations(id) {
        return this.http.get(environment.api + '/companies_shipping_destinations/by_company_id/' + id);
    }

    sendInvite(cID: number) {
        return this.http.post(environment.api + '/admin/companies/send_invite_by_id', cID);
    }

    bulkInvites(compIDs: number[]) {
        return this.http.post(environment.api + '/admin/companies/bulk_invites', compIDs);
    }

    updateContractStatus(data) {
        this.auth.setContractStatus(data.contract_status)
        return this.http.put(environment.api + '/companies/update_contract_status_by_id/' + data.id, data);
    }

    GetContractStatusByID(id) {
        return this.http.get(environment.api + '/companies/contract_status_by_id/' + id);
    }

    updateInvoiceNumbers() {
        return this.http.get(environment.api + '/updateInvoiceNumbers');
    }

    nextInvoiceNumber() {
        return this.http.get(environment.api + '/nextInvoiceNumber');
    }

    invoiceReport(){
		return this.http.get(environment.api + "/invoiceReport");
    }

    attendeeList(){
		return this.http.get(environment.api + "/attendeeList");
    }

    noManifestReport(){
		return this.http.get(environment.api + "/noManifestReport");
    }

    noAuthorizedShippingDestinationsReport(){
		return this.http.get(environment.api + "/noAuthorizedShippingDestinationsReport");
    }

    shippingDestinationsReport(){
		return this.http.get(environment.api + "/shippingDestinationsReport");
    }

}
