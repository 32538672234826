import { Component, OnInit } from '@angular/core';
import { Page } from '../../../models/page';
import { PageService } from '../../../common/services/page.service';
import { MenuService } from '../../../common/services/menu.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SlugifyPipe } from 'ngx-pipes';

@Component({
	selector: 'app-edit-page',
	templateUrl: './edit-page.component.html',
	styleUrls: ['./edit-page.component.css'],
	providers: [SlugifyPipe]
})
export class EditPageComponent implements OnInit {
	options = {
		height: 500,
		menubar: 'edit view insert format tools table tc help',
		body_class: 'site-content',
		plugins: [
			'advlist autolink lists link image charmap print preview anchor hr',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount'
		],
		importcss_append: true,
		relative_urls: false, 
		convert_urls: false,
		content_css: [
			'/assets/content.css',
			'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
		],
		toolbar: 'save_exit exit undo redo | formatselect |fontselect fontsizeselect bold italic forecolor backcolor | media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
	};
	pageID = 0;
	page = new Page();
	menus;
	constructor(
		private PageService: PageService,
		private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private ActivatedRoute: ActivatedRoute,
		private slugifyPipe: SlugifyPipe,
	) { }

	ngOnInit() {
		this.MenuService.getAll().subscribe(r => {
			this.menus = r;
		});
		this.ActivatedRoute.params.subscribe(data => {
			this.PageService.get(data['id']).subscribe(r => {
				this.page.patch(r);
			});
		});

	}

	save() {
		this.page.slug = this.slugifyPipe.transform(this.page.name);
		this.PageService.save(this.page).subscribe(r => {
			this.MatSnackBar.open("Page Saved!", null, { duration: 3000 });
			this.Router.navigate(["/", "app", "admin", "pages"]);
		});
	}

}
