import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Manifest } from 'src/app/models/manifest';
import { ManifestService } from 'src/app/common/services/manifest.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { MatDialog, MatSort, MatTableDataSource, MatSnackBar, MatPaginator } from '@angular/material';
import { LabelDialogComponent } from './label-dialog/label-dialog.component';
import { ManifestDialogComponent } from './manifest-dialog/manifest-dialog.component';
import { FileServerService } from 'src/app/common/services/file-server.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, findIndex } from 'rxjs/operators';
import { DepartmentService } from 'src/app/common/services/department.service';
import { ShippingDestinationService } from 'src/app/common/services/shipping-destination.service';
import { DatePipe } from '@angular/common';
import { AddressService } from 'src/app/common/services/address.service';
// import { ConsoleReporter } from 'jasmine';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as cloneDeep from 'lodash/cloneDeep';


@Component({
	selector: 'app-shipments',
	templateUrl: './shipments.component.html',
	styleUrls: ['./shipments.component.css']
})

export class ShipmentsComponent implements OnInit {

	public filterForm: FormGroup;

	displayedColumns: string[];
	shipments;
	shipmentsArray;
	manifestShippingStatus;
	manifestExceptionsNote;
	downloadingXLS = [];
	@ViewChild('TABLE') table: ElementRef;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('exceptionsNoteContent') exceptionsNoteContent: ElementRef;
	departments: any;
	shippingDestinations: Object;
	exceptionsNoteFocused = [];
	trackingInfoFocused = [];
	manifestLineItems;
    downloading = false;
    manifestPrintData;

	constructor(
		public ManifestService: ManifestService,
		public AuthService: AuthService,
		private ds: DepartmentService,
		private sds: ShippingDestinationService,
		public dialog: MatDialog,
		public FileServerService: FileServerService,
		private _fb: FormBuilder,
		private route: ActivatedRoute,
		private _snack: MatSnackBar,
		private router: Router,
		private datepipe: DatePipe,
		private AddressService: AddressService,
	) {
		this.displayedColumns = [
			'manifest_number',
			'company_name',
			'department_name',
			'shipping_destination',
			'shipment_method',
			'ship_date',
			'arrive_date',
			'shipment_status',
			'exceptions_note',
			'tracking_info',
			'shipping_contact_name',
			'shipping_contact_email',
			'shipping_contact_phone',
			'brand',
			'items',
			'donate_sum',
			'uploaded',
			'actions'];
	}

	ngOnInit() {

        console.log(this.AuthService.getRole().toLowerCase());

		      this.ds.allByLabelASC().subscribe(r => {
			this.departments = r;
		});

		      this.sds.all().subscribe(r => {
			this.shippingDestinations = r;
		});

		      this.filterForm = this._fb.group({
			company: new FormControl(),
			department: new FormControl(),
			destination: new FormControl(),
			shippingMethod: new FormControl(),
			displaySetType: new FormControl(),
			shipOnly: new FormControl(),
			manifestNumber: new FormControl(),
			disposition: new FormControl(),
			shipmentStatus: new FormControl(),
			isCustomsShipment: new FormControl(),
			uploadedMin: new FormControl(),
			uploadedMax: new FormControl(),
		});

		      this.route.queryParams.subscribe(v => {
			this.filterForm.patchValue({
				company: v.company || null,
				department: parseInt(v.department)  || null,
				destination: parseInt(v.destination)  || null,
				shippingMethod: v.shippingMethod || null,
				displaySetType: v.displaySetType || null,
				shipOnly: parseInt(v.shipOnly)  || null,
				manifestNumber: parseInt(v.manifestNumber)  || null,
				disposition: v.disposition || null,
				shipmentStatus: v.shipmentStatus || null,
				isCustomsShipment: v.isCustomsShipment || null,
				uploadedMin: this.datepipe.transform(v.uploadedMin, 'yyyy-MM-dd') || null,
				uploadedMax: this.datepipe.transform(v.uploadedMax, 'yyyy-MM-dd') || null,
			}, {emitEvent: false});
			this.ManifestService.filterManifestByID(v, this.isAdminCompany()).subscribe(r => {
				this.shipments = new MatTableDataSource(r as any);
				this.shipments.sort = this.sort;
				this.shipmentsArray = r;
    this.shipments.paginator = this.paginator;

    console.log('TEST');
    console.log(this.shipmentsArray);
    console.log(this.shipments);

				for (const i in this.shipmentsArray) {
					if (this.shipmentsArray[i]) {
						this.ManifestService.getManifestLineItemsByManifestID(this.shipmentsArray[i].id).subscribe(r => {
							this.shipmentsArray[i].line_items = r;
						});
						// this.ManifestService.getManifestPackagesByManifestID(this.shipmentsArray[i].id).subscribe(r => {
			      		// 	this.shipmentsArray[i]['packages'] = r;
			    		// });
						// this.sds.getShippingLabel(this.shipmentsArray[i].shipping_destination_id, this.shipmentsArray[i].company_id).subscribe(r => {
			      		// 	this.shipmentsArray[i]['shipping_destination'] = r;

			      			// this.ds.get(this.shipments[i]['shipping_destination']['department_id']).subscribe(r => {
			      			//     this.shipments[i]['department'] = r;
			      			// });

			      		// 	this.AddressService.getFullAddressByID(this.shipmentsArray[i]['shipping_destination']['mailing_address_id']).subscribe(r => {
			          	// 		this.shipmentsArray[i]['company_mailing_address'] = r;
			      		// 	});
						//
			    		// });
					}
				}
			});
		});


		      this.filterForm.valueChanges.pipe(
			debounceTime(500),
			).subscribe(v => {
				this.router.navigate([], {queryParams: v});
        });

	}

	getShippingDestinationName(id: number): string {
		const shippingDestination = this.shippingDestinations[(this.shippingDestinations as {id: number}[]).findIndex( sD => sD.id == id)];
		if (shippingDestination) {
			return shippingDestination.name;
		}
		return '';
	}

	isAdminCompany() {
		if (this.currentRole().includes('admin') || this.currentRole().includes('vendor') || this.currentRole().includes('coordinator')) {
			return 0;
		}
		return this.AuthService.getUser().company_id;
	}

	isAuthorizedExceptionsNote() {
		if (this.currentRole().includes('admin') || this.currentRole().includes('vendor') || this.currentRole().includes('supplier_coordinator')) {
			return true;
		}
		return false;
	}

	isAuthorizedTrackingInfo() {
		if (this.currentRole().includes('admin') || this.currentRole().includes('supplier') || this.currentRole().includes('supplier_coordinator')) {
			return true;
		}
		return false;
	}

	updateShipmentStatus($event, ID) {
        this.manifestShippingStatus = {shipment_status: $event.value, id: ID};
        this.ManifestService.updateShipmentStatus(this.manifestShippingStatus).subscribe( r => {
            this._snack.open('Shipment status updated!', '', {duration: 3000});
        });
	}

	updateExceptionsNote(note, ID) {
		this.manifestExceptionsNote = {exceptions_note: note, id: ID};
  		this.ManifestService.updateExceptionsNote(this.manifestExceptionsNote).subscribe( r => {
			this.exceptionsNoteFocused[ID] = false;
			this._snack.open('Exceptions Note updated!', '', {duration: 3000});
        });
    }

	updateTrackingInfo(info, ID) {
  		this.ManifestService.updateTrackingInfo({tracking_info: info, id: ID}).subscribe( r => {
			this.trackingInfoFocused[ID] = false;
			this._snack.open('Tracking Info updated!', '', {duration: 3000});
        });
    }

	delete(m) {
        if (confirm('Are you sure that you want to delete manifest ' + m.manifest_number + ' ?')) {
            this.ManifestService.delete(m.id).subscribe(r => {
                this.ngOnInit();
            });
        }
	}

	getDonateSum(line_items) {
		let sum = 0;
		for (const i in line_items) {
			if ( line_items[i].donated_value > 0 ) {
				sum += line_items[i].donated_value;
			}
		}
		if (sum > 0) {
			return sum;
		}
		return '';
	}

	openPrintLabel(data) {
		this.dialog.open(LabelDialogComponent, {
			width: '900px',
			height: '800px',
			data: {
				dataKey: data
			}
		});
	}

	openManifestLabel(data) {
		this.dialog.open(ManifestDialogComponent, {
			width: '900px',
			height: '800px',
			data: {
				dataKey: data
			}
		});
	}

	bulkDownloadManifests() {
        this.shipments.paginator.pageSize = this.shipments.filteredData.length;
        this.shipments.paginator._emitPageEvent(0);

		this.downloading = true;
        this.ManifestService.ManifestsToPDF(this.shipments._renderData._value).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'manifests.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
            this.downloading = false;
            this.shipments.paginator.pageSize = 25;
            this.shipments.paginator._emitPageEvent(0);
        });
	}

	downloadPrevious(manifest) {
		this.downloadingXLS[manifest.manifest_number] = true;
		this.FileServerService.GetByFilePath('manifests/ShippingManifest2020' + manifest.manifest_number + '.xlsx').subscribe( blob => {
			const a = document.createElement('a');
			a.setAttribute('download', 'ShippingManifest2020' + manifest.manifest_number + '.xlsx');
			a.setAttribute('href', window.URL.createObjectURL(blob));
			a.click();
			this.downloadingXLS[manifest.manifest_number] = false;
		});
	}

	reset() {
		this.router.navigate([], {queryParams: {}});
	}

	currentRole() {
		return this.AuthService.getRole().toLowerCase();
	}

	selectPlaceholderClass(status) {
		if (status == 'Received/Completed') {
			return 'green';
		} else if (status == 'Received with Exceptions') {
			return 'yellow';
		} else {
			return 'dark-grey';
		}
	}


}
