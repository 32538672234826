import { IModel, Model } from './model';

export class Department extends Model{
    id: number = null;
    group_id: number = null;
    coordinator_id: number = null;
    invoice_template_id: number = null;
    name: string = null;
    label: string = null;
    active: boolean = null;

    constructor(properties?: Object) {
        super(properties)
    }
}
