import { Model, IModel } from './model';

export interface ICompany {

}

export class Company extends Model{
	id: number = null;
	department_id: number = null;
	billing_address_id: number = null;
	mailing_address_id: number = null;
	invoice_template_id: number = null;
	contract_status_id: number = null;
	company_type_id: number = null;
	name: string = null;
	invitation_code: string = null;
	participation_fee: number = null;
	invoice_sent: boolean = null;
	invoice_download: boolean = null;
	contact_name: string = null;
	contact_title: string = null;
	contact_email: string = null;
	phone_number: string = null;
	phone_ext: string = null;
	consolidated_invoice: boolean = null;
	first_time: boolean = true;
	ship_only: boolean = false;
	onsite_reps_allowed: number = null;
	notes: string = null;
    active: boolean = true;
	shipping_destinations: any[];
	same_as_billing: boolean = false;
	hide_invoice: boolean = false;

    constructor(properties?: Object) {
        super(properties)
    }
}
