import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../common/services/company.service';
import * as XLSX from 'xlsx';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AttendeeDialogComponent } from './attendee-dialog/attendee-dialog.component';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.css']
})
export class ImportsComponent implements OnInit {

  companyAttendees;
  public results = [];

  constructor(
     public cs: CompanyService,
     private snackBar: MatSnackBar,
     public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  downloadAttendeesList() {

    this.cs.attendeeList().subscribe(r => {
      this.companyAttendees = r;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.companyAttendees);
      const formattedWS = {};

      var range = XLSX.utils.decode_range(ws['!ref']);
      for(var C = range.s.r; C <= range.e.r; ++C) {
        var header = XLSX.utils.encode_col(C) + '1';
        if(!ws[header]) { continue; }
        ws[header].v = ws[header].v.split(/(?=[A-Z])/).join('_').toLowerCase();
      }

      Object.keys(ws).forEach(function(item) {
        formattedWS[item] = ws[item];
      });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      const d = new Date();
      const date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'attendees_list_' + date + '.xlsx');
      this.snackBar.open('Spreadsheet Downloaded!', null , {duration: 3000});
    });

  }

  openAttendeeList(){
    this.dialog.open(AttendeeDialogComponent, {
      width: '900px',
      height: '800px'
    });
  }

}
