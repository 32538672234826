import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../common/services/company.service';
import { StateService } from '../common/services/state.service';
import { CountryService } from '../common/services/country.service';
import { AddressService } from '../common/services/address.service';
import { DepartmentService } from '../common/services/department.service';
import { AuthService, AuthGroups } from '../common/services/auth.service';
import { UserService } from '../common/services/user.service';
import { Company } from '../models/company';
import { User } from '../models/user';
import { Address } from '../models/address';
import { Department } from '../models/department';
import { log } from 'src/app/common/log/log';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

    shipOnly: boolean;
    company = new Company();

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private cs: CompanyService,
      private ss: StateService,
      public us: UserService,
      private CountryService: CountryService,
      private _fb: FormBuilder,
      private as: AddressService,
      private _snack: MatSnackBar,
      private ds: DepartmentService,
      private auth: AuthService
  ) { }

  ngOnInit() {
    this.shipOnly = JSON.parse(this.auth.getShipOnly())
      let user = new User(JSON.parse(localStorage.getItem("user")));
      this.cs.get(user.company_id).subscribe( r => {
          Object.assign(this.company, r);
          this.cs.GetContractStatusByID(this.company.contract_status_id).subscribe( r => {
              this.company['contract_status'] = r
          })
      });
  }

  accept(){

    console.log("TEST");
      console.log(this.company);

      if(this.company['contract_status'] === 'Not Presented'){
          this.company['contract_status'] = "Accepted Before Presented"
      }else{
          this.company['contract_status'] = "Accepted"
      }
      
      this.cs.updateContractStatus(this.company).subscribe( r => {
          this.auth.setContractStatus(this.company['contract_status'])
          this._snack.open("Contract Accepted!", "OK", {
              duration: 7000,
          });
          this.router.navigate(['/']);
      })
  }

}
