import { Injectable } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SidebarService {

	private _open$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	private _hasSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	public isMobile;

	get open$() {
		return this._open$.asObservable();
	}

	get hasSidebar$() {
		return this._hasSidebar$.asObservable();
	}

	constructor() { }

	open(){
		this._open$.next(true);
	}

	close(){
		if(this.isMobile)
			this._open$.next(false);
	}

	toggle(){
		this._open$.next(!this._open$.value);
	}

	isOpen(){
		return this._open$.value;
	}

	pageHasSidebar() {
		return this._hasSidebar$.value;
	}

	setPageHasSidebar(hasSidebar){
		this._hasSidebar$.next(hasSidebar);
	}

}
