import { Model } from './model';

export class ShippingDestination extends Model {
   id: number = null;
	name: string = null;
	address_id: number = null;
	inbound_lock: Date = null;
	outbound_lock: Date = null;
	receiving_hours: string = null;
	contact_name: string = null;
	contact_email: string = null;
	contact_phone: string = null;
   country_id: number = null;
   ship_company: string = null;
   address_1: string = null;
   address_2: string = null;
   address_3: string = null;
   city: string = null;
   state_id: string = null;
   postcode: string = null;
   custom_lock_dates: boolean = false;
	active: boolean = false;
   other_state: string = null;
   other_country: string = null;
}