import { Component, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FieldBuilder, IField } from '../../common/generic';
import { USER_FIELD_LIST, USER_PASSWORD_FIELD_LIST } from '../fields';
import { log } from 'src/app/common/log/log';
import { UserService } from '../../../common/services/user.service';
import { CompanyService } from '../../../common/services/company.service';
import { Department } from '../../../models/department';
import { User } from '../../../models/user';
import { Company } from '../../../models/company';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from '../../../common/services/role.service';
import { DepartmentService } from '../../../common/services/department.service';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { AuthService, AuthGroups } from '../../../common/services/auth.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  private _errorPrefix = EditUserComponent.name;
  private _inputFields: IField<any>[][] = [];
  form: FormGroup;
  passwordForm: FormGroup;
  id;
  roles;
  companies;
  departments;
  departmentName;
  authGroups = AuthGroups

  companyContactInfo = {
    id: null,
    contact_name: '',
    contact_title: '',
    contact_email: '',
    phone_number: '',
    phone_ext: ''
  };
  
  get inputFields(): IField<any>[][] {
    if ( this._inputFields.length === 0) {
      this._inputFields = FieldBuilder.groupByColumns(FieldBuilder.fields(USER_FIELD_LIST).filter(field => !field.group && (!field.type || field.type === 'input')));
    }
    return this._inputFields;
  }
  get company(): IField<any> {
    return USER_FIELD_LIST.company || null;
  }
  constructor(
    private _fb: FormBuilder,
    private us: UserService,
    private rs: RoleService,
    private cs: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private DepartmentService: DepartmentService,
    private _snackBar: MatSnackBar,
    private _location: Location,
    public AuthService: AuthService
  ) {
      log.Debug("user fields list: ", USER_FIELD_LIST)
    this.form = this._fb.group(FieldBuilder.getGroup(USER_FIELD_LIST, this._fb));
    this.passwordForm = this._fb.group(FieldBuilder.getGroup(USER_PASSWORD_FIELD_LIST, this._fb));
    log.Debug("form: ", this.form)
  }

  @HostBinding('class') classes = 'full-height no-head';

  ngOnInit() {
    this.route.params.subscribe(v => {
      this.id = parseInt(v['id']);
      forkJoin(
        this.us.getByID(v['id']),
        this.DepartmentService.all(),
        this.us.getDepartmentsByUser(v["id"])
      ).subscribe((
        [user, departments, chosenDepartments]: 
        [User, any[], any[]]  
      ) => {

        this.form.addControl("id", this._fb.control(v["id"]));
        //user
        this.form.patchValue(user);

        //departments
        this.departments = departments;
        let chosen = chosenDepartments.map((cd) => cd["department_id"]);

        this.form.addControl('chosenDepartments', 
          this._fb.array(
            this.departments.map(d => {
              d.selected = chosen.indexOf(d.id) !== -1  ? true : false;
              return this._fb.group(d);
            })
          )
        );

        this.cs.get(user.company_id).subscribe((c: Company) => {
          this.DepartmentService.get(c.department_id).subscribe((d: Department) => {
            this.departmentName = d.name; 
          });
        });

      });
    });

    this.rs.all().subscribe(r => {
      this.roles = r;
    });
    this.cs.getCompanies().subscribe(r => {
      this.companies = r;
    });
  }

  save() {
    log.Debug(`${this._errorPrefix} saving: `, this.form);
    let user = new User(this.form.value);
    user.is_primary = this.form.value.is_primary;
    user.departments = this.form.value.chosenDepartments.filter((d) => d.selected).map((d) => d.id);

    if(user.is_primary == true && user.company_id > 0){
      this.companyContactInfo.id = user.company_id
      this.companyContactInfo.contact_name = user.first_name + ' ' + user.last_name
      this.companyContactInfo.contact_title = user.title
      this.companyContactInfo.contact_email = user.email
      this.companyContactInfo.phone_number = user.phone
      this.companyContactInfo.phone_ext = user.ext
      this.cs.updateContactInfoByID(this.companyContactInfo).subscribe();
    }
    this.us.save(user).subscribe(r => {
      this._snackBar.open('User Updated!',null ,{duration: 3000});
      this._location.back();
    });
  }

  changePassword() {
      let user = new User(this.passwordForm.value);
      user.id = this.id
      this.us.changePassword(user).subscribe( r => {
          this._snackBar.open('User password changed!',null ,{duration: 3000});
          this.router.navigate(['/', 'app', 'admin', 'users'])
      })
  }

  sendResetPasswordLink() {
      this.us.sendPasswordResetLink(this.form.value).subscribe(r => {
            log.Debug("reset password response: ", r)
            this._snackBar.open("An email with a link to reset their password has been sent to " + this.form.value.email + " !",null ,{duration: 3000});
        });
  }

}
