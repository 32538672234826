import { Pipe, PipeTransform } from '@angular/core';
@Pipe({  name: 'orderBy', pure: false })
export class OrderByPipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {
        if (records.length <= 1) {
            return records;
        }
        return records.sort(function(a, b) {
            const direction = args.direction ? args.direction : 1;
            let aData = a;
            let bData = b;
            if (args.property) {
                aData = a[args.property];
                bData = b[args.property];
            }
            if (args.type === 'time') {
                aData = aData.getTime();
                bData = bData.getTime();
            }
            if (!args.type || args.type === 'string') {
                aData = aData.toLowerCase();
                bData = bData.toLowerCase();
            }

            switch (true) {
                case aData < bData:
                    return -1 * direction;
                case aData > bData:
                    return 1 * direction;
                default:
                    return 0;
            }
        });
    }
}
