import { Component, OnInit } from '@angular/core';
import { ContentService } from '../common/services/content.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { PageService } from '../common/services/page.service';
import { Page } from '../models/page';
import { Company } from '../models/company';
import { AuthService } from '../common/services/auth.service';
import { CompanyService } from '../common/services/company.service';
import { map } from 'rxjs/operators';
import { log } from 'src/app/common/log/log';

@Component({
	selector: 'app-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

	public page;
  private company;

	constructor(
		public ContentService: ContentService,
		public route: ActivatedRoute,
    public router: Router,
		private PageService: PageService,
        public auth: AuthService,
        private comps: CompanyService,
	) {
		this.page = new Page();
        this.company = new Company();
	}

	ngOnInit() {
        this.route.params.subscribe(data => {
            this.PageService.getBySlug(data['slug']).subscribe(r => {
                this.page = r;
            })
        });
        const user = this.auth.getUser()
        log.Debug("user.company_id: ", user.company_id)
        this.comps.get(user.company_id).subscribe(r => {
            this.company = r;
        })
        log.Debug("page: ", this.page)
        log.Debug("company: ", this.company)

        this.router.events.subscribe((evt) => {
          let content = document.querySelector('mat-sidenav-content');
          if(typeof content.scrollTo === 'function')
            content.scrollTo(0, 0);
        });
	}

    showContractAccepted() {
        let contractStatus = this.auth.getContractStatus()
        return this.page.slug == 'overview' && contractStatus == "Accepted";
    }
    
	print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              	body {
              		font-family: sans-serif;
              	}
              	h1,h2,h3,h4,p,body,td,tr,th,li,span {
              		font-size: 13px !important;
              	}
              </style>
            </head>
            <body onload="window.print();window.close()">
            ${finalPrintContents}
            </body>
          </html>`
        );
        popupWin.document.close();
    }

}
