import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { log } from 'src/app/common/log/log';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class ManifestService {

    constructor(
        public http: HttpClient,
        private auth: AuthService
    ) { }

    blankXLS(id) {
        return this.http.get(environment.api + '/manifests/download_blank/' + id, { responseType: 'blob' });
    }

    prefilledXLS(id) {
        return this.http.get(environment.api + '/manifests/download_prefilled/' + id, { responseType: 'blob' });
    }

    getAll() {
        return this.http.get(environment.api + '/manifests/all');
    }

    getAllByCompanyID(id) {
        return this.http.get(environment.api + '/manifests/by_company_id/' + id);
    }

    getAllDetailsByCompanyID(id) {
        return this.http.get(environment.api + '/manifests/details_by_company_id/' + id);
    }

    get(id){
        return this.http.get(environment.api + "/manifests/by_id/" + id);
    }

    save(data){
		return data.id ? this.update(data) : this.create(data);
	}

	update(data){
		return this.http.put(environment.api + "/manifests/update/" + data.id, data);
    }

    updateShipmentStatus(data) {
        return this.http.put(environment.api + '/manifest/update_shipment_status_by_id/' + data.id, data);
    }

    updateExceptionsNote(data) {
        return this.http.put(environment.api + '/manifest/update_exceptions_note_by_id/' + data.id, data);
    }

    updateTrackingInfo(data) {
        return this.http.put(environment.api + '/manifest/update_tracking_info_by_id/' + data.id, data);
    }

	create(data){
		return this.http.post(environment.api + "/manifests/new", data);
	}

	delete(id){
		return this.http.delete(environment.api + "/manifests/delete/" + id);
	}

    validate(file, id): Observable<string[]>{
        return this.http.post<string[]>(environment.api + "/manifests/validate/"+id, file);
    }

    filterManifestLineItems(params) {
        return this.http.get(environment.api + '/manifest_line_items/filter', { params: params });
    }

    filterManifest(params) {
        return this.http.get(environment.api + '/manifest/filter', { params: params });
    }

    filterManifestByID(params, id) {
        return this.http.get(environment.api + '/manifest_by_id/filter/' + id, { params: params });
    }

    getAllManifestLineItems() {
        return this.http.get(environment.api + '/manifest_line_items/all');
    }

    getManifestLineItemsByManifestID(id) {
        return this.http.get(environment.api + '/manifest_line_items/by_manifest_id/' + id);
    }

    getManifestPackagesByManifestID(id) {
        return this.http.get(environment.api + '/manifest_packages/by_manifest_id/' + id);
    }

    upload(file): Observable<string[]>{
        return this.http.post<string[]>(environment.api + "/manifests/upload", file);
    }

    donationsReport(){
		return this.http.get(environment.api + "/donationsReport");
    }

    ManifestToPDF(id: number) {
        return this.http.get(environment.api + "/manifests/manifest_to_pdf/"  + id, {responseType: 'blob'});
    }

    ManifestsToPDF(manifests) {
        return this.http.post(environment.api + "/manifests/manifests_to_pdf", manifests, {responseType: 'blob'});
    }
}
