import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http'; 
import { log } from 'src/app/common/log/log';

@Injectable({
    providedIn: 'root'
})

export class InvoiceTemplateService {

    constructor(
        private http: HttpClient
    ){}

    delete(id){
        return this.http.delete(environment.api + "/invoice_templates/delete/" + id);
    }

    create(data) {
        return this.http.post(environment.api + "/invoice_templates/create", data);
    }

    update(data){
        return this.http.put(environment.api + "/invoice_templates/update/" + data.id, data);
    }

    save(data){
        return data.id ? this.update(data):  this.create(data);
    }

    all(){
        return this.http.get(environment.api + "/invoice_templates/all");
    }

    get(id){
        return this.http.get(environment.api + "/invoice_templates/by_id/" + id);
    }
    
    makeDefault(data){
        return this.http.post(environment.api + "/invoice_templates/default", data)
    }
    uploadInvoiceTemplate(file){
        return this.http.post(environment.api + "/invoice_templates/upload", file);
    }

    uploadInvoiceTemplateForCompany(id, file){
        return this.http.post(environment.api + "/invoice_templates/uploadForCompany/" + id, file);
    }

}