import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GroupService } from '../../../common/services/group.service';
import { Group } from '../../../models/group';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'app-add-group',
	templateUrl: './add-group.component.html',
	styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {

	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private gs: GroupService,
		private router: Router,
		private sb: MatSnackBar,
		private _location: Location
	) { }

	ngOnInit() {
		this.form = this.fb.group({id:null, name:"", active: null});
	}

	save(){
		let group = Object.assign(new Group(), this.form.value);
		this.gs.save(group).subscribe(r => {
			this.sb.open("Group Saved", null, {duration:3000});
			this.router.navigate(["/", "app", "admin", "groups"]);
		});
	}

}
