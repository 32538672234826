import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { log } from 'src/app/common/log/log';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AddressService {

	constructor(
		public http: HttpClient,
	) { }

	create(data){
		return this.http.post(environment.api + '/addresses/create', data);
	}

	update(data){
		return this.http.put(environment.api + '/admin/addresses/update/' + data.id, data);
	}

	get(id){
		if(id == null)
			return of(false);
		return this.http.get(environment.api + "/addresses/by_id/" + id);
	}

	getFullAddressByID(id){
		if(id == null)
			return of(false);
		return this.http.get(environment.api + "/addresses/full_address_by_id/" + id);
	}

	all(){
		return this.http.get(environment.api + "/addresses/all");
	}

	save(data){
        log.Debug("id in address service: ", data.id)
        log.Debug("boolean in address service: ", Boolean(data.id))
		return Boolean(data.id) ? this.update(data) : this.create(data);
	}

	delete(id){
		return this.http.delete(environment.api + "/addresses/delete" + id);
	}
}
