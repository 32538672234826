import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'; 
import { Location } from '@angular/common';
import { InvoiceTemplateService } from '../../../common/services/invoice-template.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-edit-invoice-template',
  templateUrl: './edit-invoice-template.component.html',
  styleUrls: ['./edit-invoice-template.component.css']
})
export class EditInvoiceTemplateComponent implements OnInit {


  	public fileData;
	public invoice: FormGroup;

	constructor(
		public formBuilder: FormBuilder,
		private _location: Location,
		public InvoiceTemplateService: InvoiceTemplateService,
		public MatSnackBar: MatSnackBar,
		public Router: Router,
		public ActivatedRoute: ActivatedRoute
	) { }

	ngOnInit() {
		this.ActivatedRoute.params.subscribe(r => {
			this.InvoiceTemplateService.get(r['id']).subscribe(template => {
				console.log(template);
				this.invoice = this.formBuilder.group(template);
			});
		});
	}

	public upload($event) {
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.InvoiceTemplateService.uploadInvoiceTemplate(fileData).subscribe(r => {
			this.invoice.patchValue({file: r});
		});
	}

	public reset(){
		this.invoice.patchValue({file: null});
	}

	public save(){
		this.InvoiceTemplateService.save(this.invoice.value).subscribe(r => {
			this.MatSnackBar.open("Invoice Template Saved!", null, {duration: 3000, verticalPosition: 'top'});
			this.Router.navigate(['/', 'app', 'admin', 'invoice_templates']);
		});
	}
}
