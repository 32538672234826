import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractStatusService } from '../../common/services/contract-status.service';
import { SupplierService } from '../../common/services/supplier.service';
import { GroupService } from '../../common/services/group.service';
import { RoleService } from '../../common/services/role.service'
import { CompanyService } from '../../common/services/company.service';
import { CompanyTypeService } from '../../common/services/company-type.service';
import { DepartmentService } from '../../common/services/department.service';
import { MatSnackBar, MatSort, MatSortable, MatTableDataSource, MatPaginator } from '@angular/material';
import { debounceTime } from 'rxjs/operators';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-suppliers',
	templateUrl: './suppliers.component.html',
	styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {

    public filterForm: FormGroup;
    public departmentFilterCtrl: FormControl = new FormControl();
	public suppliers;
	public contractStatuses;
	public groups;
	public departments;
	public companyTypes;
    public roles;
    filteredDepartments;
	suppliersForExport

	@ViewChild('TABLE') table: ElementRef;

	public displayedColumns = [
	'department' , 'company' , 'contact_name',
	'company_type', 'email', 'contract_status', 'is_primary',
	'participation_fee', 'first_time', 'company_active', 'ship_only', 'onsite_reps_allowed',
	'attendees_registered', 'user_name', 'edit_company_user', 'notes'
	];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		public fb: FormBuilder,
		public css: ContractStatusService,
		public ss: SupplierService,
        public rs: RoleService,
		public cs: CompanyService,
		public cts: CompanyTypeService,
		public gs: GroupService,
		public ds: DepartmentService,
		private _snackBar: MatSnackBar,
		public router: Router,
		public route: ActivatedRoute
	) { }

	ngOnInit() {

		this.css.all().subscribe(r => {
            this.contractStatuses = r;
        });

        this.rs.all().subscribe(r => {
        this.roles = r;
        })

        this.cts.all().subscribe(r => {
            this.companyTypes = r;
        });

        this.ds.allByLabelASC().subscribe(r => {
            this.departments = r;
            this.filteredDepartments = this.departments;
        });

        this.gs.all().subscribe(r => {
            this.groups = r;
        });

		this.filterForm = this.fb.group({
			company_name: new FormControl(),
            supplier_type: new FormControl(),
			contact_name: new FormControl(),
			company_type_id: new FormControl(),
			group_id: new FormControl(),
			department_id: new FormControl(),
			contract_status_id: new FormControl(),
			company_active: new FormControl(true),
			ship_only: new FormControl(),
			account_set_up: new FormControl(),
			role: new FormControl()
		});

        this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({
                'company_name': v['company_name'] || null,
                'supplier_type': v['supplier_type'] || null,
                'role': v['role'] || null,
                'contact_name': v['contact_name'] || null,
                'department_id': parseInt(v['department_id'])  || null,
                'company_type_id': parseInt(v['company_type_id'])  || null,
                'group_id': parseInt(v['group_id']) || null,
                'contract_status_id': parseInt(v['contract_status_id']) || null,
                'account_set_up': parseInt(v['account_set_up'])  || null,
                'company_active': v['company_active'] || 1,
                'ship_only': v['ship_only'] || null,
            }, {emitEvent: false});
            this.ss.getSuppliers(v).subscribe(r => {
                this.suppliers = new MatTableDataSource(<any> r);
                this.suppliers.sort = this.sort;
                this.suppliers.paginator = this.paginator;
            })
        });


		this.filterForm.valueChanges.pipe(
            debounceTime(500),
          ).subscribe(v => {

           this.router.navigate([], {queryParams: v});
        });

        this.departmentFilterCtrl.valueChanges.subscribe(() => {
            this.filterDepartments();
        });
    }

    protected filterDepartments() {
        if (!this.departments) {
          return;
        }
        // get the search keyword
        let search = this.departmentFilterCtrl.value;
        if (!search) {
          this.filteredDepartments = this.departments;
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the companies
        this.filteredDepartments = this.departments.filter(department => department.name.toLowerCase().indexOf(search) > -1);
    }

	ExportTOExcel() {

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.suppliers.data);
        var formattedWS = {};
        var badColumn;

        Object.keys(ws).forEach(function (item) {
            if(!item.includes(badColumn)){
                if(ws[item]['v'] == ' block '){
                    ws[item]['v'] = 'no';
                }
                if(ws[item]['v'] == ' done '){
                    ws[item]['v'] = 'yes';
                }
                formattedWS[item] = ws[item];
            }
        });

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
        var d = new Date();
        var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
        XLSX.writeFile(wb, 'users_spreadsheet_'+date+'.xlsx');
        this._snackBar.open('Exported!',null ,{duration: 3000});

    }

    reset() {
        this.router.navigate([], {queryParams: {}});
    }

}
