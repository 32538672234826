import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../common/services/auth.service';
import { UserService } from '../common/services/user.service';
import { Router } from '@angular/router';
import { log } from 'src/app/common/log/log';
import { MatSnackBar } from '@angular/material';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

	public loginForm;
    public resetPasswordForm;

	constructor(
		public formBuilder: FormBuilder,
		public AuthService: AuthService,
		public router: Router,
        private us: UserService,
        private MatSnackBar: MatSnackBar
	) { }

	ngOnInit() {
		this.loginForm = this.resetPasswordForm = this.formBuilder.group({
			username: new FormControl(),
			password: new FormControl()
		});
	}

	login(){
		this.AuthService.login(this.loginForm.value).subscribe(r => {
			this.router.navigate(['app']);
		}, err => {
			console.log(err);
			this.MatSnackBar.open(err, null, {duration:10000, panelClass: 'alert', verticalPosition:"top"});
		});
	}
}
