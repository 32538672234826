import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CountryService {

	constructor(
		public http: HttpClient,
	) { }

	getAll(){
		// return this.http.get(environment.api + '/admin/users/all');
		// return this.http.get(environment.api + '/departments/all');
		// return this.http.get(environment.api + "/countries/all");
		return this.http.get(environment.api + "/countries/all");
	}
}