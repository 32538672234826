import { Routes } from '@angular/router';
import { SHIPMENT_ROUTES, SHIPMENT_COMPONENTS } from './shipment'
import { ShippingComponent } from './shipping.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { InformationComponent } from './information/information.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { ContentComponent } from '../content/content.component';
import { OutboundDispositionSearchComponent } from './outbound-disposition-search/outbound-disposition-search.component';
import { DownloadManifestComponent } from './download-manifest/download-manifest.component';
import { UploadManifestComponent } from './upload-manifest/upload-manifest.component';
import { ShipmentResolve } from './shipment/shipment.resolve';
import { ManifestInfoComponent } from './manifest-info/manifest-info.component';
import { CurrentManifestInfoResolve } from './manifest-info/current-manifest-info.resolve';
import { UnderConstructionGuardService as UnderConstructionGuard} from '../common/services/under-construction-guard.service';


export const SHIPPING_ROUTES: Routes = [
    {
        path: 'shipping',
        component: ShippingComponent,
        canActivate: [UnderConstructionGuard],
        children: [
            {
                path: '',
                redirectTo: 'information',
                pathMatch: 'full'
            },
            {
                path:"info/:slug",
                component: ContentComponent
            },
            {
                path:'information',
                component: InformationComponent
            },
            {
                path:'shipments',
                component: ShipmentsComponent
            },
            {
                path:'outbound-disposition-search',
                component: OutboundDispositionSearchComponent
            },
            {
                path: 'shipment/:id',
                component: ShipmentComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                children: [
                    ...SHIPMENT_ROUTES
                ],
                resolve: {data: ShipmentResolve}
            },
            {
                path: "download_manifest",
                component: DownloadManifestComponent
            },
            {
                path: "upload_manifest",
                component: UploadManifestComponent
            },
            {
                path: "manifest-info/:id",
                component: ManifestInfoComponent,
                resolve: {
                    manifestInfo: CurrentManifestInfoResolve,
                }
            }

        ]
    },

]

export const SHIPPING_COMPONENTS = [
    ...SHIPMENT_COMPONENTS,
    ShipmentsComponent,
    ShipmentComponent,
    ShippingComponent,
    InformationComponent,
    InstructionsComponent,
    DownloadManifestComponent,
    UploadManifestComponent,
    ManifestInfoComponent,
]
