import { Component, OnInit } from '@angular/core';
import { Page } from '../../../models/page';
import { PageService } from '../../../common/services/page.service';
import { MenuService } from '../../../common/services/menu.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SlugifyPipe } from 'ngx-pipes';

@Component({
	selector: 'app-add-page',
	templateUrl: './add-page.component.html',
	styleUrls: ['./add-page.component.css'],
	providers: [SlugifyPipe]
})
export class AddPageComponent implements OnInit {
	options = {
		  height: 500,
		  menubar: false,
		  plugins: [
		    'advlist autolink lists link image charmap print preview anchor',
		    'searchreplace visualblocks code fullscreen',
		    'insertdatetime media table paste code help wordcount'
		  ],
		  toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
		};
	pageID = 0;
	page = new Page();
	menus;
	constructor(
		private PageService: PageService,
        private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private slugifyPipe: SlugifyPipe,
	) { }

	ngOnInit() {
		this.MenuService.getAll().subscribe(r => {
			this.menus = r;
		});
	}

	save() {
		this.page.slug = this.slugifyPipe.transform(this.page.name);
		this.PageService.save(this.page).subscribe(r => {
			this.MatSnackBar.open("Page Added!", null, { duration: 3000 });
			this.Router.navigate(["/", "app", "admin", "pages"]);
		});
	}

}
