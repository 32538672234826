import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Routes, RouterModule } from '@angular/router';

@Injectable({
	providedIn: 'root'
})

export class EventService {

    IsUnderConstruction: boolean = false

	constructor(
		public http: HttpClient
	) { }



}
