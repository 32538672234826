import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CompanyTypeService {

	constructor(
		public http: HttpClient
	) { }

	all(){
		return this.http.get(environment.api + "/company_types/all");
	}

}
