import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { Company } from '../../models/company';
import { log } from 'src/app/common/log/log';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class PaymentsService {

	constructor(
		public http: HttpClient,
        private auth: AuthService
    ) { }
    
    paymentInfo(){
        return this.http.get(environment.api + "/payments/info");
    }

    payInvoice(payload){
        return this.http.post(environment.api + "/payments/payInvoice", payload);
    }

    myInvoice(){
        return this.http.get(environment.api + "/payments/myInvoice");
    }

    filterPayments(filters) {
        return this.http.get(environment.api + "/payments/filter", {params: filters});
    }

    payMyInvoice(data){
        return this.http.post(environment.api + "/pay/MyInvoice", data)
    }

    payCustomInvoice(id, data){
        return this.http.post(environment.api + "/pay/custom_invoices/"+ id , data);
    }

    InvoicePaymentInfo(id){
        return this.http.get(environment.api + "/payments/custom_invoice/"+id);
    }
}