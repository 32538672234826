import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DepartmentService {

	constructor(
		public http: HttpClient
	) { }

	all(){
		return this.http.get(environment.api + "/departments/all");
	}

	allByLabelASC(){
		return this.http.get(environment.api + "/departments/all_by_label_asc");
	}

	statuses(){
		return this.http.get(environment.api + "/admin/departments/statuses");
	}

	filter(filters) {
		return this.http.get(environment.api + '/admin/departments/all', {params: filters});
	}

	get(id) {
		return this.http.get(environment.api + "/departments/by_id/" + id);
	}

	save(data){
		return data.id ? this.update(data) : this.create(data);
	}

	update(data){
		return this.http.put(environment.api + "/admin/departments/update/" + data.id, data);
	}

	create(data){
		return this.http.post(environment.api + "/departments/create", data);
	}

	delete(id){
		return this.http.delete(environment.api + "/admin/departments/delete/" + id);
	}
}
