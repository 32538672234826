import { Component, OnInit, Inject, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InviteDialogComponent } from './invite-dialog/invite-dialog.component';
import { CompanyService } from '../../common/services/company.service';
import { DepartmentService } from '../../common/services/department.service';
import { GroupService } from '../../common/services/group.service';
import { Company } from '../../models/company';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar, 
  MatSort,
  MatSortable,
  MatTableDataSource,
  MatPaginator
} from '@angular/material';
import { AuthService } from 'src/app/common/services/auth.service';

@Component({
	selector: 'app-invites',
	templateUrl: './invites.component.html',
	styleUrls: ['./invites.component.css']
})
export class InvitesComponent implements OnInit {

  public filterForm: FormGroup;

  public departments;
	public companies;
  public groups;

	public selected = [];

  @ViewChild('TABLE') table: ElementRef;

	public displayedColumns = [
        'select',
        'department',
        'name',  
        'contact_name', 
        'contact_email', 
        'company_type', 
        'participation_fee',
        'invitation_code',
        'invite_sent', 
        'last_invite_sent', 
        'actions'
    ];
    
    @HostBinding('class') classes = 'full-height no-head';
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		public dialog: MatDialog,
		public cs: CompanyService,
    private _snack: MatSnackBar,
    public FormBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public gs: GroupService,
    public DepartmentService: DepartmentService,
    public AuthService: AuthService
    ){

	}

	ngOnInit() {

        this.selected = [];

        this.DepartmentService.allByLabelASC().subscribe(r => {
            this.departments = r;
        });

        this.gs.all().subscribe(r => {
            this.groups = r;
        });

        this.filterForm = this.FormBuilder.group({
            'department_id': new FormControl(),
            'group_id': new FormControl(),
            'name': new FormControl(),
            'invite_sent_bool': new FormControl()
        });

        this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({  
              'name': v['name'] || "",
              'department_id': parseInt(v['department_id'])  || 0,
              'group_id': parseInt(v['group_id'])  || 0,
              'invite_sent_bool': parseInt(v['invite_sent_bool'])  || null
            }, {emitEvent: false});
            this.cs.getCompanies(v).subscribe(r => {
              console.log(r);
                this.companies = new MatTableDataSource(<any> r);
                this.companies.sort = this.sort;
                this.companies.paginator = this.paginator;
            })
        });

        this.filterForm.valueChanges.subscribe(v => {

           this.router.navigate([], {queryParams: v});
        });
    }


	openInvite(){
		this.dialog.open(InviteDialogComponent, {
			width: '900px',
			height: '800px'
		});
	}

	isSelected(company){
        let id = company.id;
        return this.selected.indexOf(id) !== -1;
  }

    toggleSelection($event, company){
        if(this.isSelected(company)){
            this.selected.splice(this.selected.indexOf(company.id), 1);
        }
        else{
            this.selected.push(company.id);
        }
    }

    toggleAll(){
    	if(this.selected.length > 0){
    		this.selected = [];
    	}
    	else{
    		this.selected = this.companies.filteredData.map((c) => c.id);
    	}
    }

    allSelected(){
      if(typeof this.companies != 'undefined'){
        let companies = this.companies.filteredData.map((c) => c.id);
        return this.selected.length === companies.length;
      }
    }

    sendInvite( cID: number, cName: string): void {
      if (confirm('Are you sure that you want to send an invite to ' + cName + '?')) {
        this.cs.sendInvite(cID).subscribe( r => {
          this.cs.getCompanies(this.route.snapshot.queryParams).subscribe(r =>{
              this.companies = new MatTableDataSource(<any> r);
              this.companies.sort = this.sort;
          });
          this._snack.open("Invite Sent!", null, {duration: 3000})

        });
      }
    }

    sendInvites(){
    	if(confirm("This will send " + this.selected.length + " invites, are you sure?")){
    		this.cs.bulkInvites(this.selected).subscribe(r => {
          this.cs.getCompanies(this.route.snapshot.queryParams).subscribe(r =>{
              this.companies = new MatTableDataSource(<any> r);
              this.companies.sort = this.sort;
          });
    			this._snack.open("Invites Sent!", null, {duration: 3000})
	    		this.selected = [];
    		});

    	}
    }

    reset() {
        this.router.navigate([], {queryParams: {}});
    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:first-of-type {display: none;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:first-of-type {display: none;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

}
