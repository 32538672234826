import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { FormGroup, FormBuilder } from '@angular/forms';
import { log } from 'src/app/common/log/log';
import { FieldBuilder, IField } from '../admin/common/generic';
import { USER_FIELD_LIST, USER_PASSWORD_FIELD_LIST } from '../admin/users/fields';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../common/services/user.service';
import { AuthService } from '../common/services/auth.service';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {

    id;

    currentUser;
    form: FormGroup;
    passwordForm: FormGroup;

  constructor(
      private _fb: FormBuilder,
      private _snack: MatSnackBar,
      private us: UserService,
      private as: AuthService,
  ) { 
    this.form = this._fb.group(FieldBuilder.getGroup(USER_FIELD_LIST, this._fb));
  }

  ngOnInit() {


      this.currentUser = new User(JSON.parse(localStorage.getItem("user")));

      this.us.getByID(this.currentUser['id']).subscribe((u: User) => {
        console.log(u);
        this.id = u.id;
        this.form.patchValue(u);
      });
      this.passwordForm = this._fb.group(FieldBuilder.getGroup(USER_PASSWORD_FIELD_LIST, this._fb));
      this.form.addControl('passwordGroup', this.passwordForm);
      log.Debug(this.form);
  }

  save() {
      let user = new User(this.form.value);
      user.id = this.id;
      user.password = this.form.value.passwordGroup.password;
      log.Debug("user: ", user);
      this.us.save(user).subscribe(r => {
          this._snack.open('Personal Information Saved!',null ,{duration: 3000, horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['yellow-snackbar', 'largeSnack']});
    });
  }
  
}
