import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CompanyService } from '../../../common/services/company.service';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-invite-dialog',
	templateUrl: './invite-dialog.component.html',
	styleUrls: ['./invite-dialog.component.css']
})
export class InviteDialogComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<InviteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public cs: CompanyService,
		private sb: MatSnackBar,
		private router: Router
	) { }

	ngOnInit() {
	}

	public results = [];

	public uploaded = false;

	public uploading = false;
	public validating = false;
	public validated = false;
	public valid;
	public errors;

	public displayedColumns = ['department', 'company', 'contact_name', 'contact_email', 'participation_fee'];

	public afuConfig = {
		formatsAllowed: '.csv,.xlsx,.xlsx',
		uploadAPI: {
			url: "http://localhost/responses/invite-response.json",
			headers: {
				"Content-Type": "application/json"
			}
		}
	};

	public upload($event) {
		this.uploading = true;
		this.validated = false;
		var files = $event.target.files;
		var i, f;
		let self = this;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			var reader = new FileReader();
			var name = f.name;
			reader.onload = function (e) {
				//console.log(e);
				// var data = e.target.result;
				var data = e.target["result"];
				var workbook = XLSX.read(data, { type: 'binary' });

				//console.log(workbook);
				let result = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
				console.log("TEST: ");
				console.log(result);
				result.forEach((v: object) => {
					let row = {};
					for (let key in v) {
						row[key.toLowerCase().trim().split(" ").join("_")] = v[key];
					}
					console.log(row);
					if (row['department_label'])
						row['department_label'] = row['department_label'].toString();
					self.results.push(row);
				});
				self.uploaded = true;
				self.uploading = false;
				/* DO SOMETHING WITH workbook HERE */
			};
			reader.readAsBinaryString(f);
		}
	}

	public validate() {

		console.log("TEST");
		console.log(this.displayedColumns);

		this.validating = true;
		var index = this.displayedColumns.indexOf('errors');
		if (index > -1) {
			this.displayedColumns.splice(index, 1);
		}
		this.cs.validateImport(this.results).subscribe(r => {
			this.validating = false;
			this.valid = r['valid'];
			this.errors = r['errors'];
			this.displayedColumns.push("errors");
			this.validated = true;
		})
	}

	public clear() {
		this.uploaded = false;
		this.valid = false;
		this.validated = false;
		this.results = [];
	}

	public import() {
		this.uploading = true;
		if (!this.valid) {
			if (!confirm("The import has errors. Are you sure that you want to continue?")) {
				this.uploading = false;
				return;
			}
		}
		this.cs.invite(this.results).subscribe(r => {
			this.uploading = false;
			var errorCount = Object.keys(r).length;
			if (errorCount > 0) {
				var deptLabels = "";
				for (var i = 0; i < errorCount; i++) {
					deptLabels += "(" + r[i] + ") ";
				}
			}
			if (errorCount < this.results.length) {
				if (errorCount > 0) {
					this.sb.open("Not all imports completed. Some entered departments do not exist. " + deptLabels, "Ok");
					this.router.navigate(['/', 'app', 'admin', 'companies']);
				} else {
					this.sb.open("Import Complete", null, { duration: 3000 });
					this.router.navigate(['/', 'app', 'admin', 'companies']);
				}
			} else {
				if (errorCount > 1) {
					this.sb.open("Departments do not exist. " + deptLabels, "Ok");
				} else {
					this.sb.open("Department does not exist. " + deptLabels, "Ok");
				}
			}
			this.dialogRef.close();
		});
	}

}
