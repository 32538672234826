import { Component, OnInit } from '@angular/core';
import { IFormGroup, FieldBuilder } from 'src/app/admin/common/generic';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Manifest } from 'src/app/models/manifest';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css']
})
export class ShipmentComponent implements OnInit {
  isNew = false;
  form: FormControl;
  manifest: Manifest = new Manifest();
  fieldList: IFormGroup = {

  };
  public shipment = {};

  constructor(
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    public route: ActivatedRoute
    ) {
      // this.form = this._fb.group(FieldBuilder.getGroup(this.fieldList));
      this._route.data.subscribe(r => {
        console.log("shipment r: ", r);
        this.manifest.patch(r.data.manifest);
      });
      // const num = this._route.snapshot.paramMap.get('id');
      // if (num === 'new') {
      //   this.isNew = true;
      // } else {
      //   this.isNew = false;
      // }
    }

  ngOnInit() {
    console.log("parent route: ", this._route)
    this._route.params.subscribe( r => {
      console.log("parent params r: ", r)
      const num = r.id;
      if (num === 'new') {
        this.isNew = true;
      } else {
        this.isNew = false;
      }

    })

  }

}
