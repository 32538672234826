import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.css', '../pages.component.css']
})
export class PageComponent implements OnInit {
    pageID: number;
    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        private _route: ActivatedRoute
    ) {
        this.pageID = +this._route.snapshot.paramMap.get('page_id');
    }

    ngOnInit() {
    }

}
