import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { ShippingDestinationService } from 'src/app/common/services/shipping-destination.service';
import { DepartmentService } from 'src/app/common/services/department.service';
import { AddressService } from 'src/app/common/services/address.service';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-label-dialog',
  templateUrl: './label-dialog.component.html',
  styleUrls: ['./label-dialog.component.css']
})
export class LabelDialogComponent implements OnInit {

  form: FormGroup;
  parentComponent;
  selectedID;
  shippingDestination={};
  departmentInfo={};
  companyMailingAddress={};
  

  constructor(
    public dialogRef: MatDialogRef<LabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public sds: ShippingDestinationService,
    public ds: DepartmentService,
    public as: AddressService
  ) {}

  ngOnInit() {

    this.sds.getShippingLabel(this.data.dataKey.shipping_destination_id, this.data.dataKey.company_id).subscribe(r => {
        this.shippingDestination = r;

        this.ds.get(this.shippingDestination['department_id']).subscribe(r => {
            this.departmentInfo = r;
        });

        this.as.getFullAddressByID(this.shippingDestination['mailing_address_id']).subscribe(r => {
            this.companyMailingAddress = r;
        });

    });

  }

  print(): void {
      let printContents, popupWin;
      printContents = document.getElementById('print-section').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
          <title>Print Label</title>
            <style>
            body {font-family: sans-serif;}
            .label-container {
                border: 3px solid #222;
                padding: 20px;
            }
            .label-left {
                float: left;
                width: 55%;
            }
            .label-right {
                position: relative;
                float: right;
                width: 45%;
                text-align: center;
            }
            .data-input {
                border: 1px solid #999;
                margin-bottom: 20px;
                width: 380px;
            }
            .data-input, textarea {
                box-shadow: none;
                padding: 5px 2px;
                border: 1px solid #999;
            }
            .address {
                border: 1px solid #999;
                padding: 2.5px;
                height: 130px;
                width: 265px;
                line-height: 100%;
            }
            .address p {
                margin: 5px 0;
            }
            .clear {
                clear: both;
            }
            .left-table th {
                width: 50px;
                text-align: right;
                padding-right: 10px;
                font-size: 14px;
            }
            .right-table tr {
                text-align: center;
                width: 80%;
                margin: 0 10%;
            }
            .right-table {
                margin-bottom: 20px;
            }
            .right-table, .right-table td, .right-table th, .right-table .data-input {
                text-align: center;
            }
            .left-table td {
                width: 250px;
                text-align: left;
                font-size: 18px;
            }
            td .data-input, td textarea {
                width: 270px;
                margin: 10px 0;
            }
            .left-side data-input {
                box-shadow: none;
            }
            .dept {
                font-size: 90px;
                text-align: center;
            }
            .piece-no {
                width: 280px;
                margin: -10px auto 20px auto;
                position: relative;
                height: 100px;
            }
            .left-of {
                border: 1px solid #999;
                position: absolute;
                top: 0;
                float: left;
                width: 100px;
                height: 100px;
                box-shadow: inset 0 0 0 1000px #fff8d2;
            }
            .right-of {
                border: 1px solid #999;
                position: absolute;
                top: 0;
                right: 0;
                float: right;
                width: 100px;
                height: 100px;
                box-shadow: inset 0 0 0 1000px #fff8d2;
            }
            .of {
                position: relative;
                top: 38px;
                width: 25px;
                margin: auto;
                font-weight: bold;
                font-size: 20px;
            }
            .loc-id {
                border: 1px solid #999;
                margin: -10px auto 20px auto;
                height: 150px;
                box-shadow: inset 0 0 0 1000px #fff8d2;
                width: 280px;
            }
            .deliver-to {
                border: 1px solid #999;
                width: 280px;
                margin: -10px auto 0 auto;
                padding: 5px;
                height: 190px;
                line-height: 100%;
            }
            .deliver-to p {
                margin: 8px 0;
                font-size: 16px !important;
            }
            .label-right p {
                font-size: 18px;
            }
            .label-right img {
                position: relative;
                margin: 0 0 20px 90px;
            }
            .label-left img {
                margin-bottom: 30px;
                position: relative;
                width: 500px;
                height: 50px;
            }
            </style>
          </head>
          <body onload="window.print();window.close()">
          ${printContents}
          </body>
        </html>`
      );
      popupWin.document.close();
  }

  htmltoPDF() {
    html2canvas(document.querySelector("#print-section")).then(canvas => {
      var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      var imgData  = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData,60,60,canvas.width-140, canvas.height-140);
      pdf.save('manifest_label_'+this.data.dataKey.manifest_number+'.pdf');
    });
  }

}
