import { Model } from './model';


export class Registration extends Model {
    id: number = null;
    company_id: number = null;
    badge_first_name: string = null;
    badge_last_name: string = null;
    mobile_phone: string = null;
    emergency_contact: string = null;
    emergency_contact_relationship: string = null;
    emergency_contact_phone: string = null;
    onsite_role: string = null;
    primary_onsite: boolean = null;
    email: string = null;
    title: string = null;
}