import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IField, IFormGroup, FieldBuilder } from '../../common/generic';
import { log } from 'src/app/common/log/log';

@Component({
    selector: 'pgl-destination',
    templateUrl: './destination.component.html',
    styleUrls: ['./destination.component.css']
})
export class DestinationComponent implements OnInit {
    private _errorPrefix = DestinationComponent.name;
    private _fields: IField<any>[] = [];
    private _fieldList: IFormGroup;
    private _inputSecondPanelFields: IField<any>[] = [];
    @Input() form: FormGroup;
    @Input()
    get fieldList(): IFormGroup {
        return this._fieldList;
    }
    set fieldList(val: IFormGroup) {
        if (this._fieldList) {
            return;
        }
        this._fieldList = val;
        this.form = this._fb.group(FieldBuilder.getGroup(this.fieldList, this._fb));
        log.Debug(`${this._errorPrefix} form:`, this.form);
    }
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSave: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    get fields(): IField<any>[] {
        if (this._fields.length === 0) {
            this._fields = FieldBuilder.fields(this.fieldList.firstPanel.group);
        }
        return this._fields;
    }

    get inputSecondPanelFields(): IField<any>[] {
        if ( this._inputSecondPanelFields.length === 0) {
            this._inputSecondPanelFields = FieldBuilder.fields(this.fieldList.secondPanel.group)
                                            .filter(field => (!field.type || field.type === 'input') && field.name !== 'postcode');
        }
        return this._inputSecondPanelFields;
    }
    constructor(
        private _fb: FormBuilder
    ) {

    }

    ngOnInit() {
    }

}
