import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { Invite } from '../../models/invite';

@Injectable({
    providedIn: 'root'
})
export class InviteService {

    constructor(
        public http: HttpClient,
    ) { }

    all(){
        return this.http.get(environment.api + "/invites/all");
    }

    getByCompanyID(id) {
        return this.http.get(environment.api + '/invites/by_company_id/' + id);
    }

    get(id){
        return this.http.get(environment.api + '/invites/by_id/' + id);
    }

    create(data){
        return this.http.post(environment.api + '/admin/invites/create', data);
    }

    update(data){
        return this.http.put(environment.api + '/admin/invites/update/' + data.id, data);
    }

    save(data){
        return data.id ? this.update(data) : this.create(data);
    }

    delete(id){
        return this.http.delete(environment.api + "/admin/invites/delete/" + id);
    }

}