import { Component, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators  } from '@angular/forms';
import { Address } from '../../../models/address';
import { AddressService } from '../../../common/services/address.service';
import { Company } from '../../../models/company';
import { CompanyService } from '../../../common/services/company.service';
import { CompanyTypeService } from '../../../common/services/company-type.service';
import { CountryService } from '../../../common/services/country.service';
import { DepartmentService } from '../../../common/services/department.service';
import { StateService } from '../../../common/services/state.service';
import { log } from 'src/app/common/log/log';
import { forkJoin, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractStatusService } from '../../../common/services/contract-status.service';
import { ShippingDestinationService } from '../../../common/services/shipping-destination.service';
import { Location } from '@angular/common';
import { InvoiceTemplateService } from 'src/app/common/services/invoice-template.service';
import { map, tap } from 'rxjs/operators';


@Component({
    selector: 'app-add-company',
    templateUrl: './add-company.component.html',
    styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {
    private _errorprefix = 'AddCompanyComponent';
    form: FormGroup;
    mailing: FormGroup;
    billing: FormGroup;
    nextInvoiceNumber;
    states;
    companies;
    departments;
    countries;
    statuses;
    shippingDestinations;
    sameAsBilling = false;
    billingCountry;
    mailingCountry;
    checked = true;
    active = true;
    invoice_templates;

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        private _fb: FormBuilder,
        private cs: CompanyService,
        private cts: CompanyTypeService,
        private as: AddressService,
        private ss: StateService,
        private router: Router,
        private DepartmentService: DepartmentService,
        private CountryService: CountryService,
        private _snack: MatSnackBar,
        private ContractStatusService: ContractStatusService,
        private ShippingDestinationService: ShippingDestinationService,
        private _location: Location,
        public InvoiceTemplateService: InvoiceTemplateService
    ) { }

    ngOnInit() {
        this.form = this._fb.group(new Company());
        this.mailing = this._fb.group(new Address());
        this.billing = this._fb.group(new Address());

        this.InvoiceTemplateService.all().subscribe(r => {
            this.invoice_templates = r;
        });

        this.CountryService.getAll().subscribe(r => {
           this.countries = r;
        });

        this.cts.all().subscribe(r => {
           this.companies = r;
        });

        forkJoin(
            this.ss.getAll(),
            this.ContractStatusService.all(),
            this.CountryService.getAll(),
            this.DepartmentService.allByLabelASC(),
            this.ShippingDestinationService.all()
        ).subscribe((
            [states, contractStatuses, countries, departments, shippingDestinations]:
            [object[], object[], object[], object[], object[]]
        ) => {
            this.states = states;
            this.statuses = contractStatuses;
            this.countries = countries;
            this.departments = departments;
            this.shippingDestinations = shippingDestinations.map((item) => Object.assign({selected: false}, item));
            console.log(this.shippingDestinations);
            this.form = this._fb.group(new Company());
            let sdControl = this._fb.array(
                    this.shippingDestinations.map(
                        (r) => this._fb.group(r)
                    )
                );
            console.log(sdControl);
            this.form.addControl('shipping_destinations',
                sdControl
            );
            console.log("test: ");
            this.form.controls.active.setValue(true);
            console.log(this.form);
        })

    }

    save() {

        if(this.form.invalid){
            this._snack.open('Please fill out all required fields!',null ,{duration: 3000});
            return;
        }
        let company = Object.assign(new Company(), this.form.value);
        console.log(this.form.value);
        let billing = Object.assign(new Address(), this.billing.value);
        let mailing;
        company.shipping_destinations = this.form.value.shipping_destinations.filter((r) => r.selected).map((r) => r.id);

        if(this.sameAsBilling){
            //if mailing address is selected to be the same as billing
            mailing = Object.assign(new Address(), this.billing.value);
            mailing.id = this.mailing.value.id;
        }else{
            mailing = Object.assign(new Address(), this.mailing.value);
        }

        forkJoin(
            this.as.save(billing),
            this.as.save(mailing),
            this.cs.nextInvoiceNumber(),
            this.DepartmentService.get(company.department_id)
        ).pipe(
            tap(val => console.log(`${this._errorprefix}: `, val))
        ).subscribe(([b, m, i, d]:[{id: number}, {id: number}, number, {label: string}]) => {
            company.billing_address_id = b.id;
            company.mailing_address_id = m.id;
            company.first_time = true;
            company.same_as_billing = this.sameAsBilling;
            company.invoice_number = `PW20.${d.label}.${i}`;
            console.log(company);
            this.cs.admin_save(company).subscribe(r => {
                this._snack.open('Company Saved!',null ,{duration: 3000});
                this.router.navigate(['/', 'app', 'admin', 'companies']);
            }, err => {
                this._snack.open(err.error, 'Ok', {duration:3000, verticalPosition:'top', panelClass: 'snackError'});
            });
        });
    }
}
