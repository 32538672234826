import { Model } from './model';

export class CustomInvoice extends Model {
    id: number = null;
    invoice_number: string = null;
    file: string = null;
	company_id: number = null;
	amount: number = null;
	paid: boolean = null;	
}
