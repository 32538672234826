import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GroupService } from '../../../common/services/group.service';
import { Group } from '../../../models/group';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css']
})
export class EditGroupComponent implements OnInit {
  
	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private gs: GroupService,
		private router: Router,
		private sb: MatSnackBar,
		private route: ActivatedRoute,
		private _location: Location
	) { }

	ngOnInit() {
		this.form = this.fb.group({id:null, name:"", active: null});
		this.route.params.subscribe(data => {
			this.gs.get(data['id']).subscribe(r => {
				this.form = this.fb.group(r);
			});
		});
	}

	save(){
		let group = Object.assign(new Group(), this.form.value);
		this.gs.save(group).subscribe(r => {
			this.sb.open("Group Saved", null, {duration:3000});
			this.router.navigate(["/", "app", "admin", "groups"]);
		});
	}
}
