import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { of, BehaviorSubject, Observable } from 'rxjs';
import { USERS } from '../../models/example-data/fake_users';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { log } from 'src/app/common/log/log';
import { switchMap, map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private _users$: BehaviorSubject<User[]> = new BehaviorSubject([]);

	get users$() {
		return this._users$.asObservable();
	}

	constructor(
		public http: HttpClient
		) { 
		let users = USERS.map(u => {
			return new User(u);
		});
		this._users$.next(users);
	}

	getAll(filters) {
		return this.http.get(environment.api + '/admin/users/all', {params: filters});
	}

	all(){
		return this.http.get(environment.api + '/admin/users/all');
	}

	getByID(id){
		return this.http.get(environment.api + '/admin/users/by_id/' + id);
	}

    getByHash(hash){
        log.Debug("hash: ", hash)
        return this.http.get(environment.api + '/users/by_hash/' + hash).pipe(map(u => new User(u)));
    }

	activate(data){
		return this.http.post(environment.api + '/users/activate', data).pipe(
            map( u => ({...u, ...data}) ),
            switchMap(u => this.http.post(environment.api + '/users/authenticate', u))
            );
            
	}

	add(data){
		return this.http.post(environment.api + '/admin/users/create', data);
	}

	save(data){
		return this.http.post(environment.api + '/admin/users/save_with_departments', data);
	}

	delete(id){
		return this.http.delete(environment.api + '/admin/users/delete/' + id);
	}

    changePassword(u: User){
        return this.http.put(environment.api + '/admin/users/change-password/' + u.id, u);
    }

	getCoordinators(){
		return this.http.get(environment.api + "/admin/users/all", {params: {role: "Supplier Coordinator"}});
	}

	getDepartmentsByUser(id){
		return this.http.get(environment.api + "/users_department/by_user_id/" + id);
	}

    sendPasswordResetLink(data){
        return this.http.post(environment.api + "/users/reset-password-link", data).pipe(tap(r => {
            log.Debug("send password reset response: ", r)
        }))
    }

	checkUsername(username){
		return this.http.get(environment.api + '/users/check_username/' + username);
	}

	getCompanyByInvitationCode(code){
        let response = this.http.get(environment.api + '/companies/by_invite_code/' + code).pipe(
            map( r => {
                log.Debug("r: ", r)
                let company = r["Company"]
                company["contract_status"] = r["ContractStatus"]
                return company
            })
        );
        log.Debug("response: ", response)
        return response
	}

    public login(data){
        return this.http.post(environment.api + "/users/authenticate", data).pipe(tap(r => {
            localStorage.setItem('token', r["Token"]["Token"]);
            r["User"]["password"] = ""
            localStorage.setItem("user", JSON.stringify(r["User"]));
            localStorage.setItem("role", "admin");
        }));
    }
}
