import { Component, OnInit, HostBinding } from '@angular/core';
import { InvoiceTemplateService } from 'src/app/common/services/invoice-template.service';

@Component({
	selector: 'app-invoice-templates',
	templateUrl: './invoice-templates.component.html',
	styleUrls: ['./invoice-templates.component.css']
})
export class InvoiceTemplatesComponent implements OnInit {

	public invoice_templates;

	public displayedColumns = ['name', 'file', 'default', 'actions'];
  @HostBinding('class') classes = 'full-height no-head';
	
	constructor(
    public InvoiceTemplateService: InvoiceTemplateService
  ) { }

	ngOnInit() {
    this.InvoiceTemplateService.all().subscribe(r => {
      this.invoice_templates = r;
    })
  }
  
  delete(element){
    if(confirm("Are you sure that you want to delete " + element.name)){
      this.InvoiceTemplateService.delete(element.id).subscribe(r => {
        this.ngOnInit();
      });
    }
  }

  makeDefault(element) {
    if(confirm("Are you sure that you want to make this the default invoice template ?")){
      this.InvoiceTemplateService.makeDefault(element).subscribe(r => {
        this.ngOnInit();
      });
    }
  }

	print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

}
