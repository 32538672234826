import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CompanyService } from '../../common/services/company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../common/services/department.service';
import { UserService } from '../../common/services/user.service';
import { GroupService } from '../../common/services/group.service';
import { CompanyTypeService } from '../../common/services/company-type.service';
import { ContractStatusService } from '../../common/services/contract-status.service';
import { MatSnackBar, MatSort, MatSortable, MatTableDataSource, MatPaginator } from '@angular/material';
import { log } from 'src/app/common/log/log';
import * as XLSX from 'xlsx';
import { AuthService, AuthGroups } from 'src/app/common/services/auth.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.css']
})


export class CompaniesComponent implements OnInit {
    public filterForm: FormGroup;
    authGroups = AuthGroups;
    public companiesArray = [];
    companiesForExport = [];
    public companies;
    public company;
    public departments;
    public groups;
    public companyTypes;
    public contractStatus;
    public selected = [];
    currentUserRole;
    user;
    public downloadingBulkInvoices = false;

    updatedContract;

    @ViewChild('TABLE') table: ElementRef;

    public displayedColumns = [
        'select',
        'department',
        'name',
        'participation_fee',
        'contact_name',
        'contact_email',
        'company_type',
        'invitation_code',
        'contract_status',
        'invoice_sent',
        'invoice_downloaded',
        'info_complete',
        'ship_only',
        'invite_sent',
        'active',
        'actions'
    ];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        public formBuilder: FormBuilder,
        public cs: CompanyService,
        public css: ContractStatusService,
        public us: UserService,
        public cts: CompanyTypeService,
        public router: Router,
        public route: ActivatedRoute,
        public gs: GroupService,
        public DepartmentService: DepartmentService,
        private _snack: MatSnackBar,
        public AuthService: AuthService
    ) { }

    ngOnInit() {

        this.currentUserRole = this.AuthService.getRole();

        this.selected = [];

        this.DepartmentService.allByLabelASC().subscribe(r => {
            this.departments = r;
        });

        this.gs.all().subscribe(r => {
            this.groups = r;
        });

        this.cts.all().subscribe(r => {
            this.companyTypes = r;
        });

        this.css.all().subscribe(r => {
            this.contractStatus = r;
        });

        this.filterForm = this.formBuilder.group({
            'department_id': new FormControl(),
            'company_type_id': new FormControl(),
            'group_id': new FormControl(),
            'contract_status_id': new FormControl(),
            'name': new FormControl(),
            'is_active': new FormControl(),
            'ship_only': new FormControl(),
            'info_complete': new FormControl(),
        });

        this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({
                'company_type_id': parseInt(v['company_type_id']) || null,
                'department_id': parseInt(v['department_id'])  || null,
                'group_id': parseInt(v['group_id']) || null,
                'contract_status_id': parseInt(v['contract_status_id']) || null,
                'name': v['name'],
                'is_active': 'is_active' in v ? parseInt(v['is_active']) : 1,
                'ship_only': 'ship_only' in v ? parseInt(v['ship_only']) : 0,
                'info_complete': parseInt(v['info_complete']) || null
            }, {emitEvent: false});
            this.cs.getCompanies(v).subscribe(r => {
              this.companiesArray = Object.keys(r).map(i => r[i])
                this.companies = new MatTableDataSource(<any> this.companiesArray.reverse())
                this.companies.sort = this.sort;
                this.companies.paginator = this.paginator;
            })
        });

        this.filterForm.valueChanges.pipe(
            debounceTime(500),
          ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });

        this.router.navigate([], {queryParams: this.filterForm.value});
    }

    delete(id: number, cName: string) {
        if (confirm('Are you sure that you want to delete ' + cName + '?')) {
            this.cs.delete(id).subscribe(r => {
                this.cs.getCompanies(this.route.snapshot.queryParams).subscribe(r =>{
                    this.companiesArray = Object.keys(r).map(i => r[i])
                    this.companies = new MatTableDataSource(<any> this.companiesArray.reverse());
                    this.companies.sort = this.sort;
                });
                this._snack.open('Company Deleted!',null ,{duration: 3000});
            });
        }
    }

    sendInvite( cID: number, cName: string): void {
      if (confirm('Are you sure that you want to send an invite to ' + cName + '?')) {
        log.Debug('company id: ', cID);
        this.cs.sendInvite(cID).subscribe( r => this._snack.open('Invite Sent!', null, {duration: 3000}));
      }
    }

    reset() {
        this.router.navigate([], {queryParams: {}});
    }

    ExportTOExcel() {

      this.cs.getCompaniesForExport(this.route.snapshot.queryParams).subscribe(r => {
        console.log(r)
        this.companiesForExport = Object.keys(r).map(i => r[i])
        console.log(this.companiesForExport)

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.companiesForExport);
        var formattedWS = {};
        var badColumn;

        Object.keys(ws).forEach(function (item) {
            if(!item.includes(badColumn)){
                if(ws[item]['v'] == ' block '){
                    ws[item]['v'] = 'no';
                }
                if(ws[item]['v'] == ' done '){
                    ws[item]['v'] = 'yes';
                }
                formattedWS[item] = ws[item];
            }
        });

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
        var d = new Date();
        var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
        XLSX.writeFile(wb, 'companies_spreadsheet_'+date+'.xlsx');
        this._snack.open('Exported!',null ,{duration: 3000});
      })

    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, '> No <');
        let finalPrintContents = printContents.replace(/> done </gi, '> Yes <');
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
                  th {
                      font-weight: bold;
                      font-size: 12px;
                      color: #888;
                  }
                  th:last-of-type {
                      display: none;
                  }
                  td {
                      font-size: 12px;
                      color: #222;
                      padding: 5px;
                      text-align: center;
                      border-bottom: 1px solid #ccc;
                  }
                  td:last-of-type {
                      display: none;
                  }
              </style>
            </head>
            <body onload="window.print();window.close()">
            ${finalPrintContents}
            </body>
          </html>`
        );
        popupWin.document.close();
    }

    updateInvoiceNumbers() {
        this.cs.updateInvoiceNumbers().subscribe(r => {
            this._snack.open('Updated!');
        });
    }

    updateContractStatus($event, ID) {
        this.company = {contract_status: $event.value, id: ID};
        this.cs.updateContractStatus(this.company).subscribe( r => {
            this._snack.open('Contract Updated!', '', {duration: 3000});
        });
    }

    isSelected(company){
        let id = company.id;
        return this.selected.indexOf(id) !== -1;
    }

    toggleSelection($event, company){
        if(this.isSelected(company)){
            this.selected.splice(this.selected.indexOf(company.id), 1);
        }
        else{
            this.selected.push(company.id);
        }
    }

    toggleAll(){
    	if(this.selected.length > 0){
    		this.selected = [];
    	}
    	else{
    		this.selected = this.companies.filteredData.map((c) => c.id);
    	}
    }

    allSelected(){
      if(typeof this.companies != 'undefined'){
        let companies = this.companies.filteredData.map((c) => c.id);
        return this.selected.length === companies.length;
      }
    }

    bulkDownloadInvoiceTemplates() {
        this.downloadingBulkInvoices = true
        this.cs.bulkInvoiceZip(this.selected).subscribe( blob => {
            log.Debug("bloc: ", blob)
            const a = document.createElement('a');
            a.setAttribute('download', "invoices.zip");
            a.setAttribute('href', window.URL.createObjectURL(blob));
			a.click();
            this.downloadingBulkInvoices = false

        })
    }

}
