import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../common/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { log } from 'src/app/common/log/log';
import { User } from '../../../models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
 
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {

    public resetPasswordForm;
    user: User;

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private us: UserService,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.route.data.subscribe((data: {
            user: User
        }) => {
            log.Debug("data: ", data)
            this.user = data.user
        });

        this.resetPasswordForm = this.formBuilder.group({
            password: new FormControl(),
            confirmPassword: new FormControl()
        });
    }

    resetPassword(){
        this.user.password = this.resetPasswordForm.value.password
        log.Debug("user: ", this.user)
        this.us.changePassword(this.user).subscribe(r => {
            log.Debug("reset password response: ", r)
            this._snackBar.open("Your password has been successfully updated!",null ,{duration: 3000});
            this.router.navigate(['/', 'login'])
        })
    }
}