import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { of, BehaviorSubject, Observable } from 'rxjs';
import { USERS } from '../../models/example-data/fake_users';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { log } from 'src/app/common/log/log';
import { switchMap, map } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root'
})
export class PageService {

	constructor(
		private http: HttpClient,
        private AppService: AppService
	){}

	getByMenuID(id){
		return this.http.get(environment.api + "/pages/by_menu_id/" + id);
	}

    getActiveByMenuID(id){
        return this.http.get(environment.api + "/pages/by_menu_id/" + id).pipe(
            this.AppService.isActiveFilter()
        );
    }

	getByMenu(menu){
		return this.http.get(environment.api + "/pages/by_menu/" + menu);
	}

    getActiveByMenu(menu){
        return this.http.get(environment.api + "/pages/by_menu/" + menu).pipe(
            this.AppService.isActiveFilter()
        );
    }

	getBySlug(slug){
		return this.http.get(environment.api + "/pages/by_slug/" + slug);
	}

    getActiveBySlug(slug){
        return this.http.get(environment.api + "/pages/by_slug/" + slug).pipe(
            this.AppService.isActiveFilter()
        );
    }

	filter(params){
		return this.http.get(environment.api + "/pages/filter", {params: params});
	}

	save(data){
		return data.id ? this.update(data) : this.create(data);
	}

	create(data){
		return this.http.post(environment.api + "/pages/create", data)
	}

	update(data) {
		data._exists = true;
		return this.http.put(environment.api + "/pages/update/" + data.id, data);
	}

	delete(id){
		return this.http.delete(environment.api + "/pages/delete/" + id);
	}

	get(id){
		return this.http.get(environment.api + "/pages/by_id/" + id);
	}

    updateAll(data) {
        return this.http.post(environment.api + "/pages/update_all", data);
    }
    getAll() {
        return this.http.get(environment.api + "/pages/all");
    }
    getAllActive() {
        return this.http.get(environment.api + "/pages/all").pipe(
            this.AppService.isActiveFilter()
        );
    }
    getAllSorted() {
        return this.http.get(environment.api + "/pages/all_sorted");
    }
    getAllActiveSorted() {
        return this.http.get(environment.api + "/pages/all_sorted").pipe(
            this.AppService.isActiveFilter()
        );
    }
}