import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSort, MatSortable, MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
})


export class AnalyticsComponent implements OnInit {

    @ViewChild('TABLE') table: ElementRef;

    public displayedColumns = [
        'item',
        'count',
    ];

    public analyticsAll;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.data.subscribe((data: {
            analyticsAll: any[],
        }) => {
            console.log("data: ", data)
            this.analyticsAll = data.analyticsAll;
        })
    }

}
