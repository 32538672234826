import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup } from '@angular/forms';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
	public invitationForm;

  constructor(
  	public fb: FormBuilder,
    public router: Router,
    private _snackBar: MatSnackBar,
  	) { }

  ngOnInit() {
  	this.invitationForm = this.fb.group({
  		code: new FormControl()
  	});
  }

  signup(){
    this._snackBar.dismiss();
    this.router.navigate(['activate', this.invitationForm.value.code]);
  }

}
