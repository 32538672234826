import { Component, OnInit, HostBinding } from '@angular/core';
import { MenuService } from '../common/services/menu.service';
import { SidebarService } from '../common/services/sidebar.service';
import { PageService } from '../common/services/page.service';
import { AuthService } from '../common/services/auth.service';
import { ActivatedRoute , Router } from '@angular/router';
import { log } from 'src/app/common/log/log';


@Component({
	selector: 'app-shipping',
	templateUrl: './shipping.component.html',
	styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {

	public openNav = true;
	@HostBinding('class') classes = 'full-height no-head';
    public pages = [];
    public allPages;

	constructor(
		public SidebarService: SidebarService,
		public AuthService: AuthService,
        public PageService: PageService,
        public ActivatedRoute: ActivatedRoute,
	) { }

	ngOnInit() {

        console.log('test2');
        console.log(['supplier', 'supplier_primary_contact', 'supplier_ship_only'].indexOf(this.currentRole()) == -1);
        console.log('end test2');

        this.PageService.getActiveByMenu('shipping').subscribe(r => {
            if(['supplier', 'supplier_primary_contact', 'supplier_ship_only'].indexOf(this.currentRole()) !== -1){
                r = r.filter((p) => p.name !== 'New Shipment');
            }
            this.pages = r;
            log.Debug("response: ", r)

        });
		this.SidebarService.setPageHasSidebar(true);
	}

	currentRole(){
		return this.AuthService.getRole().toLowerCase();
	}

	menuOpen() {
		return this.SidebarService.isOpen();
	}

	add() {

	}
}
