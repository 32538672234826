import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CustomInvoiceService } from 'src/app/common/services/custom-invoice.service';
import { FormGroup, FormBuilder, FormControl} from '@angular/forms';
import { CustomInvoice } from 'src/app/models/custom-invoice';
import { InvoiceTemplateService } from 'src/app/common/services/invoice-template.service';
import { LockDatesDialogComponent } from 'src/app/admin/shipping-destinations/lock-dates-dialog/lock-dates-dialog.component';

@Component({
  selector: 'app-invoices-dialog',
  templateUrl: './invoices-dialog.component.html',
  styleUrls: ['./invoices-dialog.component.css']
})
export class InvoicesDialogComponent implements OnInit {

  public displayedColumns = ['invoice_number', 'file', 'amount', 'delete'];
  public invoices;
  public addForm: FormGroup

  constructor(
    public dialogRef: MatDialogRef<InvoicesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
    public CustomInvoiceService: CustomInvoiceService,
    public FormBuilder: FormBuilder,
    public InvoiceTemplateService: InvoiceTemplateService
  ) { }

  ngOnInit() {
    this.addForm = this.FormBuilder.group(new CustomInvoice());
    this.CustomInvoiceService.getByCompany(this.data.company.id).subscribe(r => {
      this.invoices = r;
    });
  }

  add(){
    this.addForm.patchValue({company_id: this.data.company.id});
    this.CustomInvoiceService.add(this.addForm.value).subscribe(r => {
      this.ngOnInit();
    });
  }

  update(data){
    this.CustomInvoiceService.update(data).subscribe(r => {
      this.ngOnInit();
    });
  }

  delete(data){
    if(confirm("Are you sure?")){
      this.CustomInvoiceService.delete(data).subscribe(r => {
        this.ngOnInit();
      });
    }
  }

  promptXLSX() {
    alert('File must be xlsx');
  }

  upload($event) {
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.InvoiceTemplateService.uploadInvoiceTemplateForCompany(this.data.company.id, fileData).subscribe(r => {
			this.addForm.patchValue({file: r});
		});
  }
  
  uploadTo($event, index) {
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.InvoiceTemplateService.uploadInvoiceTemplateForCompany(this.data.company.id, fileData).subscribe(r => {
      this.invoices[index].file = r;
      this.update(this.invoices[index]);
		});
	}

}
