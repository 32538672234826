import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegistrationService } from 'src/app/common/services/registration.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/common/services/company.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-registration',
  templateUrl: './add-registration.component.html',
  styleUrls: ['./add-registration.component.css']
})
export class AddRegistrationComponent implements OnInit {

  public companyFilterCtrl: FormControl = new FormControl();
  public mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  form: FormGroup;
  company;
  filteredCompanies;
  public companies;
  
  constructor(
      private _fb: FormBuilder,
      private RegistrationService: RegistrationService,
      private MatSnackBar: MatSnackBar,
      private Router: Router,
      private CompanyService: CompanyService,
      private AuthService: AuthService,
      public cs: CompanyService,
      private _location: Location
  ) {
    this.form = this._fb.group({
      badge_first_name: ['', Validators.required],
      badge_last_name: ['', Validators.required],
      mobile_phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      onsite_role: ['', Validators.required],
      primary_onsite: [null],
      emergency_contact: ['', Validators.required],
      emergency_contact_relationship: ['', Validators.required],
      emergency_contact_phone: ['', Validators.required],
      company_id: ['', Validators.required],
    });
  }

  ngOnInit() {

    this.cs.all().subscribe(r => {
      this.companies = r;
      this.filteredCompanies = this.companies;
    });

    this.companyFilterCtrl.valueChanges.subscribe(() => {
      this.filterCompanies();
    });
  }

  protected filterCompanies() {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies = this.companies;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the companies
    this.filteredCompanies = this.companies.filter(company => company.name.toLowerCase().indexOf(search) > -1);
  }

  save() {

    if(this.form.invalid){
      this.MatSnackBar.open('Please fill out all required fields!', null , {duration: 3000});
      return;
    }
    this.RegistrationService.save(this.form.value).subscribe(r => {
      this.MatSnackBar.open("Registration saved successfully", 'Ok', {verticalPosition:'top'});
      this._location.back();
    }, err => {
      this.MatSnackBar.open("There was an error saving this registration: " + err, 'Ok', {verticalPosition: "top", panelClass: "largeSnack"});
    });
  }

}
