import { Component, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material';

import { ShippingDestinationService } from '../../../common/services/shipping-destination.service';
import { ShippingDestination } from "../../../models/shipping-destination";

import { StateService } from '../../../common/services/state.service';
import { CountryService } from '../../../common/services/country.service';

import { LockDatesDialogComponent } from '../lock-dates-dialog/lock-dates-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Location } from '@angular/common';

@Component({
    selector: 'app-edit-shipping-destination',
    templateUrl: './edit-shipping-destination.component.html',
    styleUrls: ['./edit-shipping-destination.component.css']
})

export class EditShippingDestinationComponent implements OnInit {
    @HostBinding('class') classes = 'full-height no-head';

    public form: FormGroup;
    public addressForm: FormGroup;
    public states;
    public countries;
    selectedCountry=0;

    constructor(
        public ShippingDestinationService: ShippingDestinationService,
        public StateService: StateService,
        public CountryService: CountryService,
        public FormBuilder: FormBuilder,
        public MatSnackBar: MatSnackBar,
        public Router: Router,
        public ActivatedRoute: ActivatedRoute,
        public MatDialog: MatDialog,
        private _location: Location
    ) { }

    ngOnInit() {
        this.form = this.FormBuilder.group(new ShippingDestination());
        this.ActivatedRoute.params.subscribe(data => {
            let id =  data['id'];
            this.ShippingDestinationService.get(id).subscribe(r => {
                let shippingDest = new ShippingDestination();
                shippingDest.patch(r);
                this.form = this.FormBuilder.group(shippingDest);
            });
        });

        this.StateService.getAll().subscribe(response => {
          this.states = response;
        });
        this.CountryService.getAll().subscribe(r => {
           this.countries = r; 
        });
    }

    save(e) {
        console.log(this.form.value);
        this.ShippingDestinationService.save(this.form.value).subscribe(response => {
            this.MatSnackBar.open("Shipping Destination Saved!", null, { duration: 3000 });
            this.Router.navigate(['/', 'app', 'admin', 'shipping_destinations']);
        });
    }

    editLockDates(sd){
        this.MatDialog.open(LockDatesDialogComponent, {
            data: sd
        });
    }

}
