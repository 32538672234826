import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { IFormGroup, IField, FieldBuilder } from '../admin/common/generic';
import { log } from '../common/log/log';
import { SidebarService } from '../common/services/sidebar.service';
import { AuthService } from '../common/services/auth.service';
import { CompanyService } from '../common/services/company.service';
import { RegistrationService } from '../common/services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

    public company;
    public form: FormGroup;
    public registrations;
    public user;

    public displayedColumns = ['firstName', 'lastName', 'email', 'role', 'actions'];

    constructor(
        private _fb: FormBuilder,
        public SidebarService: SidebarService,
        public AuthService: AuthService,
        public CompanyService: CompanyService,
        public RegistrationService: RegistrationService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.form = this._fb.group({name: ''});
        this.SidebarService.setPageHasSidebar(false);
    }

    ngOnInit() {
        this.user = this.AuthService.getUser();
        this.CompanyService.get(this.user['company_id']).subscribe(r => {
            this.company = r;
            this.RegistrationService.filter({company_id: this.user['company_id']}).subscribe(r => {
                this.registrations = r;
            });
        });

        this.route.queryParams.subscribe(v => {
            this.form.patchValue({
                'name': v['name']
            }, {emitEvent: false});
            this.RegistrationService.filter({name: v.name, company_id: this.user['company_id']}).subscribe(r => {
                this.registrations = r;
            });
        });

        this.form.valueChanges.pipe(
            debounceTime(500),//waits .5 seconds after input changes so we don't make too many requests
        ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });
    }

    delete(registration) {
        if (confirm('Are you sure that you want to delete registration?')) {
            this.RegistrationService.delete(registration.id).subscribe(r => {
                this.ngOnInit();
            });
        }
    }

}
