import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GroupService {

	constructor(
		public http: HttpClient
	) { }

	all(){
		return this.http.get(environment.api + '/groups/all');
	}

	get(id){
		return this.http.get(environment.api + "/groups/by_id/" + id);
	}

	save(data){
		return data.id ? this.update(data) : this.create(data);
	}

	update(data){
		return this.http.put(environment.api + "/admin/groups/update/" + data.id, data);
	}

	create(data){
		return this.http.post(environment.api + "/groups/create", data);
	}

	delete(id){
		return this.http.delete(environment.api + "/admin/groups/delete/" + id);
	}
}
