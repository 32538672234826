import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { IField, IFormGroup, FieldBuilder } from '../common/generic';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import * as XLSX from 'xlsx';
import { CompanyService } from 'src/app/common/services/company.service';
import { UserService } from '../../common/services/user.service';
import { DepartmentService } from '../../common/services/department.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { User } from '../../models/user';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  public form: FormGroup;
  public invoices;
  public filterForm: FormGroup;
  public companies;
  public departments;

  @ViewChild('TABLE') table: ElementRef;

  public displayedColumns = ['department', 'name', 'participation_fee', 'invoice_number', 'transaction_number',  'invoice_downloaded', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
      private _fb: FormBuilder,
      private _snack: MatSnackBar,
      private CompanyService: CompanyService,
      private ds: DepartmentService,
      private us: UserService,
      private route: ActivatedRoute,
      private router: Router
  ) {
  }

  ngOnInit() {

    this.ds.allByLabelASC().subscribe(r => {
      this.departments = r;
    });

    this.filterForm = this._fb.group({
      'department': new FormControl(),
      'company': new FormControl(),
      'transaction_number': new FormControl(),
      'invoice_number': new FormControl(),
      'hide_invoice': new FormControl()
    });

    this.route.queryParams.subscribe(v => {
        this.filterForm.patchValue({
            'department': v['department'] ? v['department'] : null,
            'company': v['company'] ? v['company'] : null,
            'transaction_number' : v['transaction_number'] ? v['transaction_number'] : null,
            'invoice_number': v['invoice_number'] ? v['invoice_number'] : null,
            'hide_invoice': v['hide_invoice'] ? v['hide_invoice'] : null
        }, {emitEvent: false})
        this.CompanyService.invoices(v).subscribe(r => {
            this.invoices = new MatTableDataSource(<any> r);
            this.invoices.sort = this.sort;
            this.invoices.paginator = this.paginator;
        });
    });

    this.filterForm.valueChanges.pipe(
        debounceTime(500),
      ).subscribe(v => {
       this.router.navigate([], {queryParams: v});
    });

  }

  ExportTOExcel() {
      // set 'dontUseColumn' to the title of unwanted column
      var dontUseColumn = "Actions";
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
      var formattedWS = {};
      var badColumn;

      Object.keys(ws).forEach(function (item) {
          if(item.includes("1") && ws[item]['v'] == dontUseColumn){
              badColumn = item.charAt(0);
          }
          if(!item.includes(badColumn)){
              if(ws[item]['v'] == " block "){
                  ws[item]['v'] = "no";
              }
              if(ws[item]['v'] == " done "){
                  ws[item]['v'] = "yes";
              }
              formattedWS[item] = ws[item];
          }
      });
      
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'companies_spreadsheet_'+date+'.xlsx');
      this._snack.open("User Saved!",null ,{duration: 3000});
      
    }

  print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

    reset() {
      this.router.navigate([], {queryParams: {}});
    }
}
