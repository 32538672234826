import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ShippingDestinationService {

	constructor(
		public http: HttpClient
	) { }

	all(){
		return this.http.get(environment.api + "/shipping_destinations/all");
	}

	get(id) {
		return this.http.get(environment.api + "/shipping_destinations/by_id/" + id);
	}

	save(data){
		return data.id ? this.update(data) : this.create(data);
	}

	update(data){
		return this.http.put(environment.api + "/admin/shipping_destinations/update/" + data.id, data);
	}

	create(data){
		return this.http.post(environment.api + "/shipping_destinations/create", data);
	}

	delete(id){
		return this.http.delete(environment.api + "/admin/shipping_destinations/delete/" + id);
	}

	getCustomLockDatesFor(id){
		return this.http.get(environment.api + "/admin/custom_lock_dates/by_shipping_destination_id/" + id);
	}

	addCustomLockDate(data){
		return this.http.post(environment.api + "/custom_lock_dates/create", data);
	}

	deleteCustomLockDate(id){
		return this.http.delete(environment.api + "/admin/custom_lock_dates/delete/" + id);
	}

	updateCustomLockDate(data){
		return this.http.put(environment.api + "/admin/custom_lock_dates/update/" + data.id, data);
	}

	getForCompany(id){
		return this.http.get(environment.api + "/shipping_destinations/by_company_id/" + id);
	}

	getShippingLabel(destination_id, company_id){
		return this.http.get(environment.api + "/shipping_label/by_destination_id/" + destination_id +"/" + company_id);
	}
}
