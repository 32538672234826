import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})

export class UnderConstructionGuardService implements CanActivate {

  constructor(
      public EventService: EventService,
      public router: Router
  ) {}

  canActivate(): boolean {
    if (this.EventService.IsUnderConstruction) {
      this.router.navigate(['under-construction']);
      return false;
    }
    return true;
  }
}
