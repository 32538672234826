import { Component, OnInit } from '@angular/core';
import { FileManagementService } from '../../common/services/file-management.service';
import { log } from 'src/app/common/log/log';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

export interface Response {
    Contents?: any[];
}

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.css']
})

export class FilesComponent implements OnInit {

    public resp: Response = {
        Contents: []
    };

    public bucketPath: string = "http://product-walk-app.s3.amazonaws.com/";

    form: FormGroup

    pool = [];

    constructor(
        private FileManagementService: FileManagementService,
        private _snack: MatSnackBar,
        private FormBuilder: FormBuilder
    ) {}

    ngOnInit() {

        this.form = this.FormBuilder.group({
            'file': new FormControl(),
        })

        this.FileManagementService.listAll().subscribe(r => {
            log.Debug("files: ", r['Contents'])
            this.resp = r
            //this.pool = r['Contents']
        })
    }

    upload($event) {
        let formData = new FormData()
        formData.append('file', $event.target.files[0])
        log.Debug("form value: ", this.form.value)
        log.Debug("formdata: ", formData)
        this.FileManagementService.upload(formData).subscribe( r => {
            log.Debug("resp: ", r)
            this._snack.open('File Uploaded!',null ,{duration: 3000});
            this.FileManagementService.listAll().subscribe( r => {
                this.resp = r
            })
        })
    }

    copyInputMessage(inputElement){
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        this._snack.open('Link copied to clipboard!',null ,{duration: 2000});
      }

    delete(file) {
        if (confirm('Are you sure that you want to delete ' + file.Key + '?')) {
            this.FileManagementService.delete(file.Key).subscribe( r => {
                this._snack.open('File Deleted!',null ,{duration: 3000});
                this.FileManagementService.listAll().subscribe( r => {
                    this.resp = r
                })
            })
        }
    }

}