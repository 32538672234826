import{ Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ManifestService } from 'src/app/common/services/manifest.service';
import { CompanyService } from 'src/app/common/services/company.service';
import { DepartmentService } from 'src/app/common/services/department.service';
import { ShippingDestinationService } from 'src/app/common/services/shipping-destination.service';

@Injectable()
export class CurrentManifestInfoResolve implements Resolve<any> {

	constructor(
        private ManifestService: ManifestService,
        private CompanyService: CompanyService,
        private DepartmentService: DepartmentService,
        private ShippingDestinationService: ShippingDestinationService,
    ){}

	resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ){
        const id = route.paramMap.get('id');
		return forkJoin(
            this.ManifestService.get(id).pipe(
                switchMap( (m: { company_id: number}) => this.CompanyService.get(m.company_id).pipe(
                    map(c => [m, c]),
                )),
                switchMap( ([m, c]: [any, {department_id: number}]) => this.DepartmentService.get(c.department_id).pipe(
                    map(d => [m, c, d]),
                )),
                switchMap( ([m, c, d]: [{shipping_destination_id: number}, any, any]) => this.ShippingDestinationService.get(m.shipping_destination_id).pipe(
                    map(s => [m, c, d, s])
                ))
            ),
            this.ManifestService.getManifestPackagesByManifestID(id),
            this.ManifestService.getManifestLineItemsByManifestID(id),
        ).pipe(
            map(([[manifest, company, department, shippingDestination], manifestPackages, manifestLineItems]
        ) => {
			return {
                manifest,
                company,
                department,
                shippingDestination,
                manifestPackages,
                manifestLineItems,

			}
		}));
	}	
}