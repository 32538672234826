import { Model } from './model';


export class User extends Model {
    id: number;
    company_id: number;
    role_id: number;
    phone: string;
    title: string;
    ext: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    is_primary: boolean;
    is_active: boolean = true;
    created: string;
    modified: string;
    active: boolean;
    username: string;
    departments: any[];

    static EmptyGroup() {
        return new User({
            company_id: null,
            role_id: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            ext: null,
            title: null,
            password: null,
            is_primary: null,
            active: null,
            username: null,
        })
    }

}