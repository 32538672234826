import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'; 
import { Location } from '@angular/common';
import { InvoiceTemplateService } from '../../../common/services/invoice-template.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
	selector: 'app-add-invoice-template',
	templateUrl: './add-invoice-template.component.html',
	styleUrls: ['./add-invoice-template.component.css']
})
export class AddInvoiceTemplateComponent implements OnInit {

	public fileData;
	public invoice: FormGroup;

	constructor(
		public formBuilder: FormBuilder,
		private _location: Location,
		public InvoiceTemplateService: InvoiceTemplateService,
		public MatSnackBar: MatSnackBar,
		public Router: Router
	) { }

	ngOnInit() {
		this.invoice = this.formBuilder.group({
			name: new FormControl(),
			file: new FormControl(),
		});
	}

	public upload($event) {
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.InvoiceTemplateService.uploadInvoiceTemplate(fileData).subscribe(r => {
			this.invoice.patchValue({file: r});
		});
	}

	public reset(){
		this.invoice.patchValue({file: null});
	}

	public save(){
		this.InvoiceTemplateService.save(this.invoice.value).subscribe(r => {
			this.MatSnackBar.open("Invoice Template Saved!", null, {duration: 3000, verticalPosition: 'top'});
			this.Router.navigate(['/', 'app', 'admin', 'invoice_templates']);
		});
	}

}
