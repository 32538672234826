import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { UserService } from '../../common/services/user.service';
import { CompanyService } from '../../common/services/company.service';
import { AddressService } from '../../common/services/address.service';
import { RoleService } from '../../common/services/role.service';
import { StateService } from '../../common/services/state.service';
import { GroupService } from '../../common/services/group.service';
import { CountryService } from '../../common/services/country.service';
import { CompanyTypeService } from '../../common/services/company-type.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../common/services/department.service';
import { MatSnackBar, MatSort, MatSortable, MatTableDataSource, MatSelectModule, MatPaginator } from '@angular/material';
import { debounceTime } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { AuthService, AuthGroups } from 'src/app/common/services/auth.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

    public filterForm: FormGroup;
    public departmentFilterCtrl: FormControl = new FormControl();
    filteredDepartments;

    public departments;
    public groups;

    public resetOrderByFields;

	public users;
    public companies;
    public companyTypes;
    public addresses;
    public countries;
    public states;
    public roles;

    public editing = '';

    authGroups = AuthGroups;

    @ViewChild('TABLE') table: ElementRef;

    public displayedColumns = [
        'department',
        'first_name',
        'username',
        'company',
        'role',
        'is_primary',
        'email',
        'active',
        'billing_address',
        'billing_city',
        'billing_state',
        'billing_zip',
        'billing_country',
        'mailing_address',
        'mailing_city',
        'mailing_state',
        'mailing_zip',
        'mailing_country',
        'actions'
    ];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        public us: UserService,
        public formBuilder: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,
        public cs: CompanyService,
        public cts: CompanyTypeService,
        public as: AddressService,
        public rs: RoleService,
        public gs: GroupService,
        private _snackBar: MatSnackBar,
        public CountryService: CountryService,
        public StateService: StateService,
        public DepartmentService: DepartmentService,
        public AuthService: AuthService
    ) { }

    ngOnInit() {
        this.DepartmentService.allByLabelASC().subscribe(r => {
            this.departments = r;
            this.filteredDepartments = this.departments;
        });

        this.gs.all().subscribe(r => {
            this.groups = r;
        });

        this.cts.all().subscribe(r => {
            this.companyTypes = r;
        });

        this.filterForm = this.formBuilder.group({
            'department_id': new FormControl(),
            'company': new FormControl(),
            'company_type_id': new FormControl(),
            'group_id': new FormControl(),
            'role': new FormControl(),
            'name': new FormControl(),
            'is_active': new FormControl(),
            'is_primary': new FormControl(),
            'company_active': new FormControl(),
            'ship_only': new FormControl(),
            'attendees_allowed': new FormControl(),
        });

        this.cs.all().subscribe(r => {
            this.companies = r;
        });

        this.as.all().subscribe(r => {
            this.addresses = r;
        });

        this.CountryService.getAll().subscribe(r => {
            this.countries = r;
        });

        this.StateService.getAll().subscribe(r => {
            this.states = r;
        });

        this.rs.all().subscribe(r => {
            this.roles = r;
        });
        
        this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({
                'company': v['company'] || null,
                'department_id': parseInt(v['department_id'])  || null,
                'company_type_id': parseInt(v['company_type_id'])  || null,
                'group_id': parseInt(v['group_id']) || null,
                'role': v['role'] || null,
                'name': v['name'] || null,
                'is_active': 'is_active' in v ? parseInt(v['is_active']) : 1,
                'active': v['active'] || null,
                'company_active': v['company_active'] || null,
                'ship_only': 'ship_only' in v ? parseInt(v['ship_only']) : 0,
                'attendees_allowed': v['attendees_allowed'] || null,
            }, {emitEvent: false});
            this.us.getAll(v).subscribe(users => {
                  this.users = new MatTableDataSource(<any> users);
                  this.users.sort = this.sort;
                  this.users.paginator = this.paginator;
            });
        });

        this.router.navigate([], {queryParams: this.filterForm.value});

        this.filterForm.valueChanges.pipe(
            debounceTime(500), // waits .5 seconds after input changes so we don't make too many requests
        ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });

        this.departmentFilterCtrl.valueChanges.subscribe(() => {
            this.filterDepartments();
        });

    }

    protected filterDepartments() {
        if (!this.departments) {
          return;
        }
        // get the search keyword
        let search = this.departmentFilterCtrl.value;
        if (!search) {
          this.filteredDepartments = this.departments;
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the companies
        this.filteredDepartments = this.departments.filter(department => department.name.toLowerCase().indexOf(search) > -1);
    }


    delete(user) {
        if (confirm('Are you sure that you want to delete user with the id: ' + user.id + '?')) {
            this.us.delete(user.id).subscribe(r => {
                this.us.getAll(this.route.snapshot.queryParams).subscribe(users => {
                    this.users = new MatTableDataSource(<any> users);
                    this.users.sort = this.sort;
                    this._snackBar.open('User Deleted!',null ,{duration: 3000});
                });
            });
        }
    }

    update($event, index, field) {
        console.log([$event, index, field]);
        this.users[index][field] = $event;
    }

    reset() {
        this.router.navigate([], {queryParams: {}});
    }

    ExportTOExcel() {
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
      var formattedWS = {};
      var badColumn;

      Object.keys(ws).forEach(function (item) {
          if(item.includes("1") && ws[item]['v'] == "Actions"){
              badColumn = item.charAt(0);
          }
          if(!item.includes(badColumn)){
              if(ws[item]['v'] == " block "){
                  ws[item]['v'] = "no";
              }
              if(ws[item]['v'] == " done "){
                  ws[item]['v'] = "yes";
              }
              formattedWS[item] = ws[item];
          }
      });
      
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
      var d = new Date();
      var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
      XLSX.writeFile(wb, 'users_spreadsheet_'+date+'.xlsx');
    }

    hasPermission(role: string, authGroup: string[]): boolean {
        if( this.authGroups.ACCOUNTING.findIndex(aRole => aRole == role.toLowerCase().replace(" ", "_")) == -1 ) {
            return true
        }
        return this.AuthService.HasPermission(authGroup)
    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
                  th {
                      font-weight: bold;
                      font-size: 12px;
                      color: #888;
                  }
                  th:last-of-type {
                      display: none;
                  }
                  td {
                      font-size: 12px;
                      color: #222;
                      padding: 5px;
                      text-align: center;
                      border-bottom: 1px solid #ccc;
                  }
                  td:last-of-type {
                      display: none;
                  }
              </style>
            </head>
            <body onload="window.print();window.close()">
            ${finalPrintContents}
            </body>
          </html>`
        );
        popupWin.document.close();
    }

}
