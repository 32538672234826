import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IField, IFormGroup, FieldBuilder } from '../../common/generic';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DEPARTMENT_FIELDS } from '../fields';

@Component({
    selector: 'pgl-department',
    templateUrl: './department.component.html',
    styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
    private _fields: IField<any>[][] = [];

    private _fieldList: IFormGroup;
    form: FormGroup;
    @Input()
    set fieldList(val: IFormGroup) {
        if (this._fieldList) {
            return;
        }
        this._fieldList = val;
        this.form = this._fb.group(FieldBuilder.getGroup(this.fieldList, this._fb));
    }
    get fieldList(): IFormGroup {
        return this._fieldList;
    }
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSave = new EventEmitter<FormGroup>();

    get fields(): IField<any>[][] {
        if (this._fields.length === 0) {
            this._fields = FieldBuilder.groupByColumns(
                FieldBuilder.fields(this.fieldList), 1);
        }
        return this._fields;
    }

    constructor(
        private _fb: FormBuilder
    ) { }

    ngOnInit() {
    }

    getError(field) {
        return FieldBuilder.getError(field, this.form.get(field.name));
    }
}
