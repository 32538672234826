import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, HostBinding} from '@angular/core';
import { ContentService } from '../common/services/content.service';
import { SidebarService } from '../common/services/sidebar.service';
import { PageService } from '../common/services/page.service';
import { ActivatedRoute , Router, NavigationEnd } from '@angular/router';
import { UserService } from '../common/services/user.service';
import { User } from '../models/user';
import { log } from 'src/app/common/log/log';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnalyticsService } from '../common/services/analytics.service';
import { Autobind } from '../common/autobind';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
    public openNav: boolean;
    public content;
    public pages;
    user;

    @HostBinding('class') classes = 'full-height no-head';
    constructor(
        public ContentService: ContentService,
        public SidebarService: SidebarService,
        private PageService: PageService,
        private ActivatedRoute: ActivatedRoute,
        public us: UserService,
        private Router: Router,
        public AnalyticsService: AnalyticsService,
    ){}

    ngOnInit() {
        this.ActivatedRoute.params.subscribe(params => {
            this.user = new User(JSON.parse(localStorage.getItem("user")));
            this.us.getByID(this.user['id']).subscribe((u: User) => {
                this.user = u;
            });

            if(this.user.role_id <= 3){
                this.PageService.getByMenu(params['menu']).subscribe(r => {
                    this.pages = r;
                    console.log(this.pages);
                    if(this.ActivatedRoute.children.length == 0){
                        this.Router.navigate([this.pages[0].slug], {relativeTo: this.ActivatedRoute});
                    }
                });
            }else{
                this.PageService.getActiveByMenu(params['menu']).subscribe(r => {
                    this.pages = r;
                    console.log(this.pages);
                    if(this.ActivatedRoute.children.length == 0){
                        this.Router.navigate([this.pages[0].slug], {relativeTo: this.ActivatedRoute});
                    }
                });
            }
        });

        if(this.ActivatedRoute.children.length == 0){
            this.Router.navigate
        }

        this.content = this.ContentService.content;
        this.openNav = true;
        this.SidebarService.setPageHasSidebar(true);
    }

    @Autobind
    SaveAnalytic(item: string) {
        console.log("save analytic item: ", item)
        this.AnalyticsService.create(
                {item: item, user_id: this.user.id, company_id: this.user.company_id}
            ).subscribe( r =>
                console.log("saved analytic: ", r)
            )
    }

    menuOpen(){
        return this.SidebarService.isOpen();
    }




}
