import { Component, OnInit, Inject } from '@angular/core';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CompanyService } from '../../../common/services/company.service';

@Component({
  selector: 'app-attendee-dialog',
  templateUrl: './attendee-dialog.component.html',
  styleUrls: ['./attendee-dialog.component.css']
})
export class AttendeeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AttendeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public cs: CompanyService,
    private sb: MatSnackBar,
    private router: Router
  ) { }

  public results = [];

  public uploaded = false;

  public uploading = false;
  public validating = false;
  public validated = false;
  public valid;
  public errors;

  public displayedColumns = ['department_label', 'company_name', 'company_id', 'allowed_attendees'];

  ngOnInit() {
  }

  public upload($event) {
    this.uploading = true;
    this.validated = false;
    var files = $event.target.files;
    var i, f;
    let self = this;
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader();
      var name = f.name;
      reader.onload = function (e) {
        //console.log(e);
        // var data = e.target.result;
        var data = e.target['result'];
        var workbook = XLSX.read(data, { type: 'binary' });

        //console.log(workbook);
        let result = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        result.forEach((v: object) => {
          let row = {};
          for (let key in v) {
            row[key.toLowerCase().trim().split(' ').join('_')] = v[key];
          }
          if (row['department_label'])
            row['department_label'] = row['department_label'].toString();
          self.results.push(row);
        });
        self.uploaded = true;
        self.uploading = false;
        /* DO SOMETHING WITH workbook HERE */
      };
      reader.readAsBinaryString(f);
    }
  }

  public updateAttendees() {
    this.uploading = true;
    // if (!this.valid) {
    //   if (!confirm("The import has errors. Are you sure that you want to continue?")) {
    //     this.uploading = false;
    //     return;
    //   }
    // }
    let updatedAttendees = [];
    this.results.forEach((v: object) => {
      updatedAttendees.push({id: v['company_id'], onsite_reps_allowed: v['allowed_attendees']});
    });
    console.log(updatedAttendees);
    this.cs.updateAttendees(updatedAttendees).subscribe(r => {
      console.log(r)
      this.uploading = false;
      this.dialogRef.close();
      const errorCount = Object.keys(r).length;
      let companyIDErrors = '';
      if (errorCount > 0) {
        for (var i = 0; i < errorCount; i++) {
          companyIDErrors += '#' + r[i] + ' ';
        }
      }
      if (errorCount < updatedAttendees.length) {
        if (errorCount > 0) {
          this.sb.open('Not all imports completed. Some entered company IDs do not exist: ' + companyIDErrors, 'Ok');
        } else {
          this.sb.open('Import Complete', null, { duration: 3000 });
        }
      } else {
        if (errorCount > 1) {
          this.sb.open('Company IDs do not exist. ' + companyIDErrors, 'Ok');
        } else {
          this.sb.open('Company ID does not exist. ' + companyIDErrors, 'Ok');
        }
      }
    });
  }

}
