import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http'; 
import { log } from 'src/app/common/log/log';

@Injectable({
    providedIn: 'root'
})

export class FileManagementService {

    constructor(
        private http: HttpClient
    ){}

    listAll() {
        return this.http.get(environment.api + "/files/list_all")
    }

    upload(data) {
        return this.http.post(environment.api + "/files/upload", data)
    }

    delete(filename) {
        return this.http.delete(environment.api + "/files/delete/" + filename);
    }

    deleteAll() {
        return this.http.delete(environment.api + "/files/delete_all");
    }
}