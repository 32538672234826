import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../../../models/user';
import { UserService } from '../../../common/services/user.service';
import { log } from 'src/app/common/log/log';

@Injectable()
export class ResetPasswordResolve implements Resolve<User> {
    constructor (
        private router: Router,
        private us: UserService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<User> {
        const user_hash = route.params.hash;
        log.Debug("hash: ", user_hash)
        return this.us.getByHash(user_hash);
    }
}