import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Registration } from '../../models/registration';
import { RegistrationService } from 'src/app/common/services/registration.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/common/services/company.service';
import { AuthService } from 'src/app/common/services/auth.service';


@Component({
  selector: 'pgl-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

    form: FormGroup;
    company;

    constructor(
        private _fb: FormBuilder,
        private RegistrationService: RegistrationService,
        private MatSnackBar: MatSnackBar,
        private Router: Router,
        private CompanyService: CompanyService,
        private AuthService: AuthService
    ) {
      this.form = this._fb.group({
        badge_first_name: ['', Validators.required],
        badge_last_name: ['', Validators.required],
        mobile_phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        onsite_role: ['', Validators.required],
        primary_onsite: [null],
        emergency_contact: ['', Validators.required],
        emergency_contact_relationship: ['', Validators.required],
        emergency_contact_phone: ['', Validators.required],
        company_id: [''],
        title: ['', Validators.required],
      });

        // this.form = this._fb.group(new Registration());


    }

    ngOnInit() {
      let user = this.AuthService.getUser();
      this.CompanyService.get(user['company_id']).subscribe(r => {
        this.company = r;
        this.form.patchValue({company_id: this.company.id});
      });
    }

    save() {

        //registration disabled
        if(true) {
            alert('Registrations are disabled, please contact your supplier coordinator for changes.')
            return;
        }

      if(this.form.invalid){
        this.MatSnackBar.open('Please fill out all required fields!', null , {duration: 3000});
        return;
      }

      this.RegistrationService.save(this.form.value).subscribe(r => {
        this.MatSnackBar.open("Registration saved successfully", 'Ok', {verticalPosition:'top', panelClass:'largeSnack'});
        this.Router.navigate(['/', 'app', 'registration']);
      }, err => {
        this.MatSnackBar.open("There was an error saving this registration: " + err, 'Ok', {verticalPosition: "top", panelClass: "largeSnack"});
      });
    }

}
