import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: '[linkWatcher]'
})

export class LinkWatcher {
    @Input('linkWatcher') SaveAnalytic: (a: any) => void;

    @HostListener("click", ["$event"])
    onClick(e: MouseEvent){
        if((event.target as Element).tagName.toLowerCase() == 'a'){
            console.log("event pathname: ", event.target['pathname'])
            this.SaveAnalytic(event.target['pathname']);
        }
    }
}
