import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { log } from 'src/app/common/log/log';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

    groups = AuthGroups;

	constructor(
		public http: HttpClient
	){ 

	}

	public isAuthenticated(): boolean {
		const token = localStorage.getItem('token');
		if(token){
			return true;
		}
		return false;
	}

	public getToken(){
		return localStorage.getItem('token');
	}

	public setToken(token){
		return localStorage.setItem('token', token);
	}

    public setContractStatus(cs){
        return localStorage.setItem('contract_status', cs)
    }

	public getUser(){
        log.Debug("user: ", JSON.parse(localStorage.getItem("user")))
		return JSON.parse(localStorage.getItem("user"));
    }
    
    public getUserID(){
        let user = this.getUser();
        return user.id;
    }

	public getRole(){
		return localStorage.getItem("role");
    }
    
    public isPrimary(){
        return this.getRole() == 'supplier_primary_contact' || this.getRole() == 'admin';
    }

    public getContractStatus(){
        return localStorage.getItem("contract_status");
    }

    public getShipOnly(){
        return localStorage.getItem("ship_only");
    }
	
	public login(data){
		return this.http.post(environment.api + "/users/authenticate", data).pipe(
            map(r => {
                if(!r)
                    throw new Error('Unable to Authenticate User');
                return r;
            }),
            tap(r => {
			localStorage.setItem('token', r["Token"]["Token"]);
            r["User"]["password"] = ""
            let role = r["Role"].toLowerCase().replace(" ", "_");
            if( "supplier" === role ){
                if(r["User"]["is_primary"]) {
                    role = role + "_primary_contact"
                }
                if(r["ShipOnly"]) {
                    role = role + "_ship_only"
                }
            }
			localStorage.setItem("user", JSON.stringify(r["User"]));
			localStorage.setItem("role", role);
            localStorage.setItem("contract_status", r["ContractStatus"]);
            localStorage.setItem("ship_only", r["ShipOnly"]);
		}));
	}

    HasPermission( ag: string[] ): boolean {
        let role = this.getRole()
        if( ag.indexOf(role) > -1 ) {
            return true
        }
        return false
    }

	public logout(){
		localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        localStorage.removeItem('contract_status');
	}
}

export enum AuthRoles {
    USER_MANAGER = "user_manager",
    SUPER_ADMIN = "super_admin",
    ADMIN = 'admin',
    ACCOUNTING = 'accounting',
    SUPPLIER_COORDINATOR = 'supplier_coordinator',
    SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY = 'supplier_primary_contact_ship_only',
    SUPPLIER_PRIMARY_CONTACT = 'supplier_primary_contact',
    SUPPLIER_SHIP_ONLY = 'supplier_ship_only',
    SUPPLIER = 'supplier',
    VENDOR = 'vendor'
}

export class AuthGroups {
    static USER_MANAGER: AuthRoles[] = [AuthRoles.USER_MANAGER, AuthRoles.ADMIN, AuthRoles.SUPER_ADMIN, AuthRoles.SUPPLIER_COORDINATOR];
    static SUPER_ADMIN: AuthRoles[] = [AuthRoles.SUPER_ADMIN];
    static ADMIN: AuthRoles[] = [AuthRoles.ADMIN, ...AuthGroups.SUPER_ADMIN];
    static ACCOUNTING: AuthRoles[] = [AuthRoles.ACCOUNTING, ...AuthGroups.ADMIN];
    static VENDOR: AuthRoles[] = [AuthRoles.VENDOR, ...AuthGroups.ACCOUNTING];
    static SUPPLIER_COORDINATOR: AuthRoles[] = [AuthRoles.SUPPLIER_COORDINATOR, ...AuthGroups.ADMIN];
    static SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY: AuthRoles[] = [
        AuthRoles.SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY,
        ...AuthGroups.SUPPLIER_COORDINATOR
    ];
    static SUPPLIER_PRIMARY_CONTACT: AuthRoles[] = [
        AuthRoles.SUPPLIER_PRIMARY_CONTACT,
        ...AuthGroups.SUPPLIER_COORDINATOR
    ];
    static SUPPLIER_SHIP_ONLY: AuthRoles[] = [AuthRoles.SUPPLIER_SHIP_ONLY, ...AuthGroups.SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY];
    static SUPPLIER: AuthRoles[] = [
        AuthRoles.SUPPLIER,
        ...AuthGroups.SUPPLIER_PRIMARY_CONTACT
    ];
}
