import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Page } from '../../../models/page';
import { PageService } from '../../../common/services/page.service';
import { MenuService } from '../../../common/services/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
	selector: 'app-edit-page-content',
	templateUrl: './edit-page-content.component.html',
	styleUrls: ['./edit-page-content.component.css']
})

export class EditPageContentComponent implements OnInit {

	options = {
		height: 500,
		menubar: 'edit view insert format tools table tc help',
		body_class: 'site-content',
		plugins: [
			'advlist autolink lists link image charmap print preview anchor hr',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount'
		],
		importcss_append: true,
		relative_urls: false, 
		convert_urls: false,
		content_css: [
			'/assets/content.css',
			'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
		],
		toolbar: 'save_exit exit undo redo | formatselect |fontselect fontsizeselect bold italic forecolor backcolor | media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
	};
	pageID = 0;
	page = new Page();
	menus;
	constructor(
		public dialogRef: MatDialogRef<EditPageContentComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private PageService: PageService,
		private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private ActivatedRoute: ActivatedRoute,
	) {
		let component = this;
		this.options['setup'] = function(editor) {

			function saveAndExit() {
				component.save();
			}

			function cancel() {
				component.cancel();
			}

			editor.on('init', function(e) {
				editor.execCommand('mceFullScreen');
			});

			editor.ui.registry.addButton('save_exit', {
				text: "Save",
				tooltip: 'Save the content and close',
				onAction: saveAndExit
			});

			editor.ui.registry.addButton('exit', {
				text: 'Exit',
				tooltip: "Cancel and Exit",
				onAction: cancel
			});
		};
	}

	ngOnInit() {

		this.PageService.get(this.data['page_id']).subscribe(r => {
			this.page.patch(r);
		});

	}

	save() {
		this.PageService.save(this.page).subscribe(r => {
			this.MatSnackBar.open("Page Saved!", null, { duration: 3000 });
			this.dialogRef.close();
		});
	}

	cancel() {
		this.dialogRef.close();
	}

}
