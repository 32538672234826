import { Component, OnInit, Injector, Inject } from '@angular/core';
import { IFormGroup } from 'src/app/admin/common/generic';
import { FormControl } from '@angular/forms';
import { log } from 'src/app/common/log/log';
import { Observable } from 'rxjs';
import { ManifestService } from 'src/app/common/services/manifest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Manifest } from 'src/app/models/manifest';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, getMatFormFieldDuplicatedHintError } from '@angular/material';

import { ShipmentComponent } from '../shipment.component';
import { FileServerService } from 'src/app/common/services/file-server.service';
import { ShipmentService } from 'src/app/common/services/shipment.service';
// import { ConsoleReporter } from 'jasmine';
import { ContactInfoResolve } from '../contact-info/contact-info.resolve';

@Component({
    selector: 'app-manifest',
    templateUrl: './manifest.component.html',
    styleUrls: ['./manifest.component.css']
})
export class ManifestComponent implements OnInit {
    public newEntry = false;
    parentComponent;
    uploadedDate = '12-25-2018';
    form: FormControl;
    progress$: Observable<number>;
    downloadingXLS = true;
    manifest: Manifest = new Manifest();
    pool: any = [];

    constructor(
        public ManifestService: ManifestService,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        private inj: Injector,
        private _snack: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public FileServerService: FileServerService,
        private MatSnackBar: MatSnackBar,
        private ShipmentService: ShipmentService
    ) {
        this.parentComponent = this.inj.get(ShipmentComponent);
        this.form = new FormControl();
    }

    ngOnInit() {

        this.newEntry = this.parentComponent.isNew;

        this.manifest.patch(this.route.snapshot.data.data.manifest);
    }

    dropUpload(files) {
        let fileData = new FormData()
        fileData.append('file', files[0])
        this.upload(fileData)
    }

    clickUpload(submission) {
        let fileData = new FormData()
        fileData.append('file', submission.target.files[0])
        this.upload(fileData, submission)
    }

    upload(fileData, $event?){
		this.ManifestService.validate(fileData, this.manifest.id).subscribe((r: string[]) => {
			if (r.length > 0) {
                if ($event)
                    $event.srcElement.value = null;
                this.dialog.open(ManifestErrorsDialogComponent, {
                    data: {errors: r}
                });
            }
            else{
				this._snack.open('Validation successful. Uploading manifest...',null ,{duration: 3000, horizontalPosition: 'center',
          			verticalPosition: 'bottom'});
				this.ManifestService.upload(fileData).subscribe( success => {
					if(!success) {
						this._snack.open('Upload failed!',null ,{duration: 3000, horizontalPosition: 'center',
							verticalPosition: 'bottom'});
					} else {
						this._snack.open('Upload successful!',null ,{duration: 3000, horizontalPosition: 'center',
                              verticalPosition: 'bottom'});
                              this.router.navigate(["../../../manifest-info", this.manifest.id], {relativeTo: this.activatedRoute})
                        this.pool = fileData
					}
				});
            }
		});
    }

    addFileByClick(files){
        this.upload(files.target.files)
    }

    backToShipments(e) {
        e.preventDefault();
        this.router.navigate(["../../../shipments"], {relativeTo: this.activatedRoute})
    }

    downloadEmpty(){
        this.downloadingXLS = true;
        if(this.newEntry) {
            this.ShipmentService.currentManifest.Schedule['shipment_status'] = "Not Received"
            console.log("debug manifest for save object: ", this.ShipmentService.currentManifest.Schedule)
            this.ManifestService.save(this.ShipmentService.currentManifest.Schedule).subscribe(r => {
                this.MatSnackBar.open("Manifest Saved!", null, {duration: 3000});
                this.manifest.patch(r);
                this.ManifestService.prefilledXLS(this.manifest.id).subscribe( blob => {
                    const a = document.createElement('a');
                    a.setAttribute("download", "ShippingManifest2020" + this.manifest.manifest_number + ".xlsx");
                    a.setAttribute("href", window.URL.createObjectURL(blob));
                    a.click();
                    this.downloadingXLS = false;
                })
              }, err => {
                this.MatSnackBar.open("Failed to save! Err: " + err, 'Ok');
              });
        } else {
            this.ManifestService.prefilledXLS(this.manifest.id).subscribe( blob => {
                const a = document.createElement('a');
                a.setAttribute("download", "ShippingManifest2020" + this.manifest.manifest_number + ".xlsx");
                a.setAttribute("href", window.URL.createObjectURL(blob));
                a.click();
                this.downloadingXLS = false;
            })
        }

    }

    downloadPrevious() {
		this.downloadingXLS = true;
		this.FileServerService.GetByFilePath("manifests/ShippingManifest2020"+this.manifest.manifest_number+".xlsx").subscribe( blob => {
			const a = document.createElement('a');
			a.setAttribute("download", "ShippingManifest2020" + this.manifest.manifest_number+".xlsx");
			a.setAttribute("href", window.URL.createObjectURL(blob));
			a.click();
			this.downloadingXLS = false;
		})
	}

}

@Component({
    selector: 'app-manifest-errors',
    template:'<div class="row" *ngFor="let err of data.errors"><div class="col">{{err}}</div></div>'
})
export class ManifestErrorsDialogComponent {
    constructor(
		public dialogRef: MatDialogRef<ManifestErrorsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) { }

}
