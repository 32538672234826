import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LabelDialogComponent } from '../shipments/label-dialog/label-dialog.component';
import { ManifestDialogComponent } from '../shipments/manifest-dialog/manifest-dialog.component';
import { AuthService } from 'src/app/common/services/auth.service';

@Component({
    selector: 'manifest-info',
    templateUrl: './manifest-info.component.html',
    styleUrls: ['./manifest-info.component.css']
})

export class ManifestInfoComponent implements OnInit {

    manifest;
    manifestPackages;
    manifestLineItems;
    company;
    department;
    shippingDestination;
    packageColumns: string[];
    lineItemColumns: string [];

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        public as: AuthService,
    ){
        this.packageColumns = [
			'quantity', 
            'type',
            'width',
            'height',
            'depth',
            'weight',
        ];
        this.lineItemColumns = [
            'item',
            'quantity',
            'description',
            'display_type',
            'sku',
            'discard',
            'return',
            'return_method',
            'donate',
            'donate_value',
            'return_address',
        ]
    }

    ngOnInit() {
        this.route.data.subscribe(data => {
            console.log("data: ", data);
            this.manifest = data.manifestInfo.manifest;
            this.company = data.manifestInfo.company;
            this.department = data.manifestInfo.department;
            this.shippingDestination = data.manifestInfo.shippingDestination;
            this.manifestPackages = data.manifestInfo.manifestPackages;
            this.manifestLineItems = data.manifestInfo.manifestLineItems;
        });
        console.log("manifest: ", this.manifest);
        console.log("manifestPackages: ", this.manifestPackages);
    }

    openPrintLabel(){
		this.dialog.open(LabelDialogComponent, {
			width: '900px',
			height: '800px',
			data: {
				dataKey: this.manifest
			}
		});
	}

	openManifestLabel(){
		this.dialog.open(ManifestDialogComponent, {
			width: '900px',
			height: '800px',
			data: {
				dataKey: this.manifest
			}
		});
    }
    
    currentRole(){
		return this.as.getRole().toLowerCase();
	}

}